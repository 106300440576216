// src/gameData/fallOfRome.ts
function abilitySpent(character) {
  return {
    name: `${character} spent`,
    type: "abilitySpent",
    target: "self"
  };
}
var ROME_CHARACTERS = [
  {
    id: "sculptor_fall_of_rome",
    name: "Sculptor",
    ability: "You start knowing a player. Each night*, you learn the alignment of their most recent nomination.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/sculptor_fall_of_rome.png",
    setup: false,
    team: "Townsfolk",
    reminders: [
      {
        name: "Sculpture",
        type: "info",
        dayReminder: true
      },
      {
        name: "Sculptor Nom",
        type: "info"
      }
    ],
    firstNight: {
      reminder: "Point to a player. Place the SCULPTURE token next to that player.",
      setReminders: ["Sculpture"],
      order: 18
    },
    otherNight: {
      reminder: "If the player with the SCULPTURE token nominated today. Show the Sculptor an evil thumbs down sign if the nominated player is evil. Otherwise: show the Sculptor a good thumbs up sign. Remove the NOMINATED token (if any).",
      order: 19
    }
  },
  {
    id: "vestalvirgin_fall_of_rome",
    name: "Vestal Virgin",
    ability: "You start knowing 1 good & 1 evil character, 1 of which is in-play. When they die, that night you learn 1 good & 1 evil character, 1 of which is in-play.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/vestalvirgin_fall_of_rome.png",
    setup: false,
    team: "Townsfolk",
    reminders: [
      {
        name: "Learns",
        type: "info"
      }
    ],
    firstNight: {
      reminder: "Show 1 good and 1 evil character token: one of these is in play. Place the LEARNS next to the in-play character. ",
      order: 19,
      setReminders: ["Learns"]
    },
    otherNight: {
      reminder: "If the player with the LEARNS token died today, remove the token. Show 1 good and 1 evil character token: one of these is in play. Place the LEARNS next to the in-play character. ",
      order: 20
    }
  },
  {
    id: "physician_fall_of_rome",
    name: "Physician",
    ability: "Each night, choose two players (not yourself): they are sober, healthy & get true info tonight. The 1st time the Demon kills one, you learn the Demon type.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/physician_fall_of_rome.png",
    setup: false,
    team: "Townsfolk",
    reminders: [
      {
        name: "Patient",
        type: "info"
      },
      {
        name: "1st Demon",
        type: "info"
      }
    ],
    firstNight: {
      reminder: "The Physician points to two players not themselves. Mark both players with the PATIENT token.",
      order: 17,
      setReminders: ["Patient"]
    },
    otherNight: {
      reminder: "Remove all PATIENT tokens. The Physician points to two players not themselves. Mark both players with the PATIENT token. If a player with a PATIENT token was killed by the Demon, place the 1ST DEMON token next to the Physician and wake the Physician and show them Demon token.",
      order: 11,
      setReminders: ["Patient"]
    }
  },
  {
    id: "legionary_fall_of_rome",
    name: "Legionary",
    ability: "Each night, you learn how many living evil players are sat clockwise between yourself and a living Legionary. [+0 to +2 Legionary]",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/legionary_fall_of_rome.png",
    setup: true,
    team: "Townsfolk",
    reminders: [],
    firstNight: {
      reminder: "Show the hand signal for the number (0, 1, 2, etc.) of living evil players between the Legionary and the next clockwise Legionary (exclusive).",
      order: 20
    },
    otherNight: {
      reminder: "Show the hand signal for the number (0, 1, 2, etc.) of living evil players between the Legionary and the next clockwise Legionary (exclusive).",
      order: 21
    }
  },
  {
    id: "trumpeter_fall_of_rome",
    name: "Trumpeter",
    ability: "Each night*, you learn how many evil players publicly claimed to be Spartacus today.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/trumpeter_fall_of_rome.png",
    setup: false,
    team: "Townsfolk",
    reminders: [
      {
        name: "Evil Claim",
        type: "info"
      }
    ],
    firstNight: null,
    otherNight: {
      reminder: "Show the hand signal for the number (0, 1, 2, etc.) of evil players who publicly claimed to be Spartacus today.",
      order: 22,
      setReminders: ["Evil Claim"]
    }
  },
  {
    id: "mortician_fall_of_rome",
    name: "Mortician",
    ability: "Each night*, if a player died by execution today you learn if either of their living neighbours are evil.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/mortician_fall_of_rome.png",
    setup: false,
    team: "Townsfolk",
    reminders: [
      {
        name: "Recently Executed",
        type: "info"
      }
    ],
    firstNight: null,
    otherNight: {
      reminder: "If a player was executed today: Show the head signal (nod 'yes', shake 'no') for whether one of the neighbours is evil.",
      order: 23,
      setReminders: ["Recently Executed"]
    }
  },
  {
    id: "standardbearer_fall_of_rome",
    name: "Standard Bearer",
    ability: "When you are nominated, you may make a unique public statement about the nominator (not yourself). Tonight, you learn if the statement was true.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/standardbearer_fall_of_rome.png",
    setup: false,
    team: "Townsfolk",
    reminders: [
      {
        name: "True",
        type: "info"
      },
      {
        name: "False",
        type: "info"
      }
    ],
    firstNight: null,
    otherNight: {
      reminder: "If the Standard Bearer's public statement was true: show the head signal (nod 'true'). Otherwise: show the head signal (shake 'false').",
      order: 24
    }
  },
  {
    id: "centurion1_fall_of_rome",
    name: "Centurion",
    ability: "If you nominate & execute a living player, their team loses. You are safe from the Demon. If you publicly claimed to be Spartacus today, you are drunk until dawn.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/centurion1_fall_of_rome.png",
    setup: false,
    team: "Townsfolk",
    reminders: [
      {
        name: "Centurion Drunk",
        type: "info"
      }
    ],
    firstNight: null,
    otherNight: null
  },
  {
    id: "merchant_fall_of_rome",
    name: "Merchant",
    ability: "Once per game, at night, choose to learn the characters of players that have nominated you.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/merchant_fall_of_rome.png",
    setup: false,
    team: "Townsfolk",
    reminders: [
      {
        name: "Merchant Nom",
        type: "info"
      },
      abilitySpent("Merchant")
    ],
    firstNight: null,
    otherNight: {
      reminder: "If the Merchant has not yet used their ability: the Merchant either shows a shake 'no' head signal, or a nod 'yes' head signal. If the Merchant chose 'yes', show the character tokens of players with a NOMINATED token (in any order). Place the NO ABILITY token.",
      order: 26,
      setReminders: ["Merchant Nom", abilitySpent("Merchant").name]
    }
  },
  {
    id: "gladiator_fall_of_rome",
    name: "Gladiator",
    ability: "Once per game, during the day, publicly choose a living player. Tonight, you and they wake & silently play roshambo: whoever loses dies (someone must lose).",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/gladiator_fall_of_rome.png",
    setup: false,
    team: "Townsfolk",
    reminders: [
      {
        name: "Duel",
        type: "info"
      },
      abilitySpent("Gladiator")
    ],
    firstNight: null,
    otherNight: {
      reminder: 'If the Gladiator used their ability today wake the Gladiator and the player they chose: both players silently play roshambo (rock "fist", scissors "two fingers" or paper "flat hand"). If they both chose the same, play again. Play until someone wins. The loser dies.',
      order: 18,
      setReminders: [abilitySpent("Gladiator").name]
    }
  },
  {
    id: "actor_fall_of_rome",
    name: "Actor",
    ability: "Once per game, during the day, publicly guess 3 players' character types (not yourself, 1 guess per type). That night, you learn how many you got correct. ",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/actor_fall_of_rome.png",
    setup: false,
    team: "Townsfolk",
    reminders: [
      {
        name: "Correct",
        type: "info"
      },
      abilitySpent("Actor")
    ],
    firstNight: null,
    otherNight: {
      reminder: "If the Actor used their ability today: Show the hand signal for the number (0, 1, 2, or 3) of CORRECT markers. Place the NO ABILITY marker.",
      order: 25,
      setReminders: [abilitySpent("Actor").name]
    }
  },
  {
    id: "blacksmith_fall_of_rome",
    name: "Blacksmith",
    ability: "The 1st time the Demon kills you, you live & gain a not-in-play Townsfolk ability.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/blacksmith_fall_of_rome.png",
    setup: false,
    team: "Townsfolk",
    reminders: [
      {
        name: "Is the Blacksmith",
        type: "info",
        persistOnDeath: true
      }
    ],
    firstNight: null,
    otherNight: {
      reminder: "If the Blacksmith was killed by a Demon. Show the Blacksmith a not-in-play Townsfolk character token. Swap the not-in-play character token with the Blacksmith token. Place the IS THE BLACKSMITH token next to the not-in-play character token. Wake the Blacksmith and show 'You are', then their new character ability.",
      order: 17
    }
  },
  {
    id: "scholar_fall_of_rome",
    name: "Scholar",
    ability: "The 1st time you nominate a living Outsider, they immediately become a not-in-play Townsfolk. [+1 Outsider]",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/scholar_fall_of_rome.png",
    setup: true,
    team: "Townsfolk",
    reminders: [
      {
        name: "Lectured",
        type: "info"
      },
      abilitySpent("Scholar")
    ],
    firstNight: null,
    otherNight: {
      reminder: "If the Scholar nominated an Outsider today, place the NO ABILITY token. Wake the nominated player. Show 'You are', then their new character token.",
      order: 2,
      setReminders: [abilitySpent("Scholar").name]
    }
  },
  {
    id: "thetwins_fall_of_rome",
    name: "The Twins",
    ability: "You start knowing a player: if either of you are executed, all Townsfolk are drunk until dusk tomorrow.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/thetwins_fall_of_rome.png",
    setup: false,
    team: "Outsider",
    reminders: [
      {
        name: "Remus",
        type: "info"
      },
      {
        name: "Townsfolk Drunk",
        type: "info"
      }
    ],
    firstNight: {
      reminder: "Point to a player. Place the REMUS token next to that player.",
      order: 16,
      setReminders: ["Remus"]
    },
    otherNight: {
      reminder: "If The Twins has a TOWNSFOLK DRUNK token, all Townsfolk are drunk until dusk.",
      order: 10,
      setReminders: ["Townsfolk Drunk"]
    }
  },
  {
    id: "winemaker_fall_of_rome",
    name: "Winemaker",
    ability: "Your Townsfolk neighbours are drunk, but every other night, you are drunk until dusk, even if you are dead.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/winemaker_fall_of_rome.png",
    setup: false,
    team: "Outsider",
    reminders: [
      {
        name: "Odd",
        type: "info"
      },
      {
        name: "Even",
        type: "info"
      },
      {
        name: "Wine Drunk",
        type: "info"
      }
    ],
    firstNight: {
      reminder: "Place either the ODD or EVEN token. If ODD, the Winemaker is drunk on the 1st night and every other night after. Otherwise: the Winemaker is drunk on the 2nd night and every other night after.",
      order: 15,
      setReminders: ["Even", "Odd"]
    },
    otherNight: {
      reminder: "Check which ODD or EVEN token is placed. If an odd night and the ODD token is placed, the Winemaker is drunk until dusk. If an even night and the EVEN token is placed, the Winemaker is drunk until dusk. Otherwise their Townsfolk neighbours are drunk until dusk.",
      order: 9,
      setReminders: ["Wine Drunk"]
    }
  },
  {
    id: "spartacus_fall_of_rome",
    name: "Spartacus",
    ability: "If an evil player guesses you (once), your team loses. You might register as a Townsfolk; each day, if you did not publicly claim to be Spartacus, you don't.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/spartacus_fall_of_rome.png",
    setup: false,
    team: "Outsider",
    reminders: [
      {
        name: "Guess Used",
        type: "info"
      }
    ],
    firstNight: null,
    otherNight: null
  },
  {
    id: "badomen_fall_of_rome",
    name: "Bad Omen",
    ability: "You do not know you are a Bad Omen. You think you are a Townsfolk, but you receive false information. You might register as evil, even if dead.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/badomen_fall_of_rome.png",
    setup: true,
    team: "Outsider",
    reminders: [
      {
        name: "Is a Bad Omen",
        type: "info"
      }
    ],
    firstNight: null,
    otherNight: null
  },
  {
    id: "temptress_fall_of_rome",
    name: "Temptress",
    ability: "On your 1st night choose two players: they learn that they were chosen. The 1st time one of them dies by execution, the other becomes evil that night.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/temptress_fall_of_rome.png",
    setup: false,
    team: "Minion",
    reminders: [
      {
        name: "Seduced",
        type: "info"
      },
      {
        name: "Evil",
        type: "info"
      }
    ],
    firstNight: {
      reminder: "The Temptress points to two players. Place the SEDUCED token next to the two players. Wake the two players separately. Show the 'This character selected you' card, then the Temptress token. ",
      order: 12,
      setReminders: ["Seduced"]
    },
    otherNight: {
      reminder: "If a player with a SEDUCED token is executed and dies, remove their SEDUCED token. The player with the remaining SEDUCED turns evil. Remove their SEDUCED token and replace it with the EVIL token. Wake the evil player and show them the 'You are' card and the thumbs down evil signal.",
      order: 3,
      setReminders: ["Seduced"]
    }
  },
  {
    id: "haruspex_fall_of_rome",
    name: "Haruspex",
    ability: "Each night, choose a player: you learn their character. The 1st player you choose twice in this way, dies. [+ Spartacus]",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/haruspex_fall_of_rome.png",
    setup: true,
    team: "Minion",
    reminders: [
      {
        name: "Haruspex Foretold",
        type: "info"
      },
      abilitySpent("Haruspex")
    ],
    firstNight: {
      reminder: "The Haruspex points to a player. Show that player\u2019s character token. Place a FORETOLD token next that player. ",
      order: 14,
      setReminders: ["Haruspex Foretold"]
    },
    otherNight: {
      reminder: "The Haruspex points to a player. Show that player\u2019s character token. Place a FORETOLD token next that player: if that player already has a FORETOLD token. That player dies, also place the CAN'T KILL token next to the Haruspex.",
      order: 16,
      setReminders: ["Haruspex Foretold", abilitySpent("Haruspex").name]
    }
  },
  {
    id: "glykon1_fall_of_rome",
    name: "Glykon",
    ability: "You might register as good. Until dawn, players you nominate register as the opposing alignment & if a Townsfolk, are also poisoned.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/glykon1_fall_of_rome.png",
    setup: false,
    team: "Minion",
    reminders: [
      {
        name: "Snake Bite",
        type: "poison"
      }
    ],
    firstNight: null,
    otherNight: null
  },
  {
    id: "augur_fall_of_rome",
    name: "Augur",
    ability: "If a Townsfolk nominates you, they immediately become a Bad Omen.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/augur_fall_of_rome.png",
    setup: false,
    team: "Minion",
    reminders: [],
    firstNight: null,
    otherNight: null
  },
  {
    id: "cleopatra_fall_of_rome",
    name: "Cleopatra",
    ability: "Each night, choose two players: if they nominate tomorrow, they die that night. Each day, if a good player (Travellers don't count) does not nominate, evil wins. ",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/cleopatra_fall_of_rome.png",
    setup: false,
    team: "Demon",
    reminders: [
      {
        name: "Cleo chosen",
        type: "info"
      }
    ],
    firstNight: {
      reminder: "Cleopatra points to two players. ",
      order: 13,
      setReminders: ["Cleo chosen"]
    },
    otherNight: {
      reminder: "If a previously chosen player nominated today they die. Remove all CHOSEN tokens. Cleopatra points to two players. Place the CHOSEN token next to both players.",
      order: 12,
      setReminders: ["Cleo chosen"]
    }
  },
  {
    id: "crassus_fall_of_rome",
    name: "Crassus",
    ability: "Each night*, choose a player: they die. If the 1st Crassus publicly claims to be Spartacus & dies with 5 or more players alive, an evil player becomes Crassus.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/crassus_fall_of_rome.png",
    setup: false,
    team: "Demon",
    setupReminders: ["OG Crassus"],
    reminders: [
      {
        name: "OG Crassus",
        type: "info"
      }
    ],
    firstNight: null,
    otherNight: {
      reminder: "Crassus points to a player. That player dies. If the 1st Crassus publicly claimed to be Spartacus today and points to themselves (or was executed), with 5 or more players alive, replace the character of 1 evil player with a spare Crassus token. Show the 'You are' card, then the Crassus token. ",
      order: 13
    }
  },
  {
    id: "hannibal_fall_of_rome",
    name: "Hannibal",
    ability: "You think you are a good character, but you are not. Minions learn 3 bluffs. Each night*, a player might die. The 1st Hannibal to die, becomes good. [+1 Hannibal] ",
    attribution: `Hannibal was awarded the 4th place trophy in the Unofficial's "Custom Demon Contest".`,
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/hannibal_fall_of_rome.png",
    setup: true,
    team: "Demon",
    reminders: [
      {
        name: "Hannibal",
        type: "info"
      }
    ],
    firstNight: {
      reminder: "Select 2 good players and place the IS HANNIBAL reminder tokens. Wake all Minions together, show them the Hannibals and show them three not-in-play character tokens (these must be the same).",
      order: 11,
      setReminders: ["Hannibal"]
    },
    otherNight: {
      reminder: "Choose a player, that player dies. If a Hannibal died today, wake that player and show them the 'You are' card, the Hannibal token and then the thumbs up good signal.",
      order: 14
    }
  },
  {
    id: "caesar_fall_of_rome",
    name: "Caesar",
    ability: "Each night*, choose a player: they die. The 1st time an evil player dies by execution, that night, choose an additional player: they die.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/caesar_fall_of_rome.png",
    setup: false,
    team: "Demon",
    reminders: [abilitySpent("Caesar")],
    firstNight: null,
    otherNight: {
      reminder: "If an evil player was executed today, Caesar points to two players. Otherwise: Caesar points to a player. Chosen players die.",
      order: 15,
      setReminders: [abilitySpent("Caesar").name]
    }
  },
  {
    id: "mercenary_fall_of_rome",
    name: "Mercenary",
    ability: "Each night*, gain the ability of a player who publicly claimed Spartacus today. If a Mercenary is exiled, you are exiled too. [+1 Mercenary of opposing alignment]",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/mercenary_fall_of_rome.png",
    setup: false,
    team: "Traveler",
    reminders: [
      {
        name: "Mercenary",
        type: "info"
      }
    ],
    firstNight: null,
    otherNight: {
      reminder: "Show the Mercenary a character that publicly claimed to be Spartacus today, they have that ability tonight and tomorrow until dusk. ",
      order: 4,
      setReminders: ["Mercenary"]
    }
  },
  {
    id: "architect_fall_of_rome",
    name: "Architect",
    ability: "Each night*, choose a player: 1) they become a not-in-play character of the same type, or 2) they swap characters with a player of the same type.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/architect_fall_of_rome.png",
    setup: false,
    team: "Traveler",
    reminders: [
      {
        name: "Redesigned",
        type: "info"
      }
    ],
    firstNight: null,
    otherNight: {
      reminder: "The Architect points to a player. Choose which ability will effect the chosen player. Treat them accordingly (not-in-play character or character swap). Wake affected players individually. Show 'You are', then their new character token.",
      order: 7,
      setReminders: ["Redesigned"]
    }
  },
  {
    id: "sibyl_fall_of_rome",
    name: "Sibyl",
    ability: "Each day, after the 1st execution, you may publicly choose a dead player: they may nominate. If the majority of the dead and yourself agree, they are executed.",
    attribution: "Original Character concept by Kohav. Many thanks to them for giving the green light to bringing it to Fall of Rome!",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/sibyl_fall_of_rome.png",
    setup: false,
    team: "Traveler",
    reminders: [],
    firstNight: null,
    otherNight: null
  },
  {
    id: "highpriest1_fall_of_rome",
    name: "High Priest",
    ability: "Each day, publicly choose a unique living player to bless: if a majority of players agree, something good happens to them.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/highpriest1_fall_of_rome.png",
    setup: false,
    team: "Traveler",
    reminders: [
      {
        name: "HP Blessed",
        type: "info"
      },
      {
        name: "HP Failed",
        type: "info"
      }
    ],
    firstNight: null,
    otherNight: {
      reminder: "If the High Priest successfully blessed a player today, something good happens to that player and/or their team.",
      order: 5
    }
  },
  {
    id: "highpriest2_fall_of_rome",
    name: "High Priest -2",
    ability: "Each day, publicly choose a unique living player to bless: if a majority of players agree, tomorrow they may learn a statement. Tonight, choose if it\u2019s true.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/highpriest2_fall_of_rome.png",
    setup: false,
    team: "Traveler",
    reminders: [
      {
        name: "HP2 Blessed",
        type: "info"
      },
      {
        name: "HP2 Failed",
        type: "info"
      }
    ],
    firstNight: null,
    otherNight: {
      reminder: 'If the High Priest successfully blessed a player today, wake the High Priest. The High Priest chooses whether the statement is true tomorrow with a hand signal (true "thumbs up", false "thumbs down").',
      order: 6
    }
  },
  {
    id: "emperor_fall_of_rome",
    name: "Emperor",
    ability: "Each day, choose the 1st execution's outcome. If you choose to protect today's execution: they survive. Otherwise, tonight you learn their alignment.",
    edition: "fall of rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/emperor_fall_of_rome.png",
    setup: false,
    team: "Traveler",
    reminders: [
      {
        name: "Emperor Executed",
        type: "info"
      }
    ],
    firstNight: null,
    otherNight: {
      reminder: "If a player was executed today and the outcome was not overturned by the Emperor: Show the hand signal (thumbs down 'evil', thumbs up 'good') for the players alignment.",
      order: 8
    }
  }
];

// src/gameData/characterData.ts
function abilitySpent2(character) {
  return {
    name: `${character} spent`,
    type: "abilitySpent",
    target: "self"
  };
}
var UNASSIGNED = {
  id: "unassigned",
  name: "Unassigned",
  edition: "",
  team: "Traveler",
  reminders: [],
  setup: true,
  delusional: false,
  ability: "Please see the storyteller for a role!",
  imageSrc: "",
  firstNight: null,
  otherNight: null
};
var CHARACTERS = [
  UNASSIGNED,
  ...ROME_CHARACTERS,
  {
    id: "artist",
    name: "Artist",
    edition: "snv",
    team: "Townsfolk",
    reminders: [{ ...abilitySpent2("artist"), dayTrigger: true }],
    setup: false,
    delusional: false,
    ability: "Once per game, during the day, privately ask the Storyteller any yes/no question.",
    imageSrc: "artist.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "assassin",
    name: "Assassin",
    edition: "bmr",
    team: "Minion",
    reminders: [abilitySpent2("assassin")],
    setup: false,
    delusional: false,
    ability: "Once per game, at night*, choose a player: they die, even if for some reason they could not.",
    imageSrc: "assassin.png",
    firstNight: null,
    otherNight: {
      setReminders: [abilitySpent2("assassin").name],
      reminder: "If the Assassin has not yet used their ability: The Assassin either shows the 'no' head signal, or points to a player. That player dies.",
      order: 36,
      kills: true
    }
  },
  {
    id: "barber",
    name: "Barber",
    edition: "snv",
    team: "Outsider",
    reminders: [
      {
        name: "haircuts tonight",
        type: "info",
        duration: 1,
        causedByDeath: true
      }
    ],
    setup: false,
    delusional: false,
    ability: "If you died today or tonight, the Demon may choose 2 players (not another Demon) to swap characters.",
    imageSrc: "barber.png",
    firstNight: null,
    otherNight: {
      reminder: "If the Barber died today: Wake the Demon. Show the 'This character selected you' card, then Barber token. The Demon either shows a 'no' head signal, or points to 2 players. If they chose players: Swap the character tokens. Wake each player. Show 'You are', then their new character token.",
      order: 40,
      setReminders: ["haircuts tonight"],
      playerMessage: {
        type: "character-selected-you",
        restriction: {
          role: ["barber"]
        }
      }
    }
  },
  {
    id: "baron",
    name: "Baron",
    edition: "tb",
    team: "Minion",
    reminders: [],
    setup: true,
    delusional: false,
    ability: "There are extra Outsiders in play. [+2 Outsiders]",
    imageSrc: "baron.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "butler",
    name: "Butler",
    edition: "tb",
    team: "Outsider",
    reminders: [
      {
        name: "master",
        type: "info",
        duration: 1,
        dayReminder: true
      }
    ],
    setup: false,
    delusional: false,
    ability: "Each night, choose a player (not yourself): tomorrow, you may only vote if they are voting too.",
    imageSrc: "butler.png",
    firstNight: {
      setReminders: ["master"],
      reminder: "The Butler points to a player. Mark that player as 'Master'.",
      order: 39
    },
    otherNight: {
      setReminders: ["master"],
      reminder: "The Butler points to a player. Mark that player as 'Master'.",
      order: 67
    }
  },
  {
    id: "cerenovus",
    name: "Cerenovus",
    edition: "snv",
    team: "Minion",
    reminders: [
      {
        name: "gone mad",
        type: "mad",
        duration: 1,
        dayReminder: true
      }
    ],
    setup: false,
    delusional: false,
    ability: "Each night, choose a player & a good character: they are \u201Cmad\u201D they are this character tomorrow, or might be executed.",
    imageSrc: "cerenovus.png",
    firstNight: {
      reminder: "The Cerenovus points to a player, then to a character on their sheet. Wake that player. Show the 'This character selected you' card, then the Cerenovus token. Show the selected character token. If the player is not mad about being that character tomorrow, they can be executed.",
      order: 25,
      setReminders: ["gone mad"],
      playerMessage: {
        type: "madness"
      }
    },
    otherNight: {
      reminder: "The Cerenovus points to a player, then to a character on their sheet. Wake that player. Show the 'This character selected you' card, then the Cerenovus token. Show the selected character token. If the player is not mad about being that character tomorrow, they can be executed.",
      order: 15,
      playerMessage: {
        type: "madness"
      }
    }
  },
  {
    id: "chambermaid",
    name: "Chambermaid",
    edition: "bmr",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each night, choose 2 alive players (not yourself): you learn how many woke tonight due to their ability.",
    imageSrc: "chambermaid.png",
    firstNight: {
      reminder: "The Chambermaid points to two players. Show the number signal (0, 1, 2, \u2026) for how many of those players wake tonight for their ability.",
      order: 51
    },
    otherNight: {
      reminder: "The Chambermaid points to two players. Show the number signal (0, 1, 2, \u2026) for how many of those players wake tonight for their ability.",
      order: 70
    }
  },
  {
    id: "chef",
    name: "Chef",
    edition: "tb",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "You start knowing how many pairs of evil players there are.",
    imageSrc: "chef.png",
    firstNight: {
      reminder: "Show the finger signal (0, 1, 2, \u2026) for the number of pairs of neighbouring evil players.",
      order: 36
    },
    otherNight: null
  },
  {
    id: "clockmaker",
    name: "Clockmaker",
    edition: "snv",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "You start knowing how many steps from the Demon to its nearest Minion.",
    imageSrc: "clockmaker.png",
    firstNight: {
      reminder: "Show the hand signal for the number (1, 2, 3, etc.) of places from Demon to closest Minion.",
      order: 41
    },
    otherNight: null
  },
  {
    id: "courtier",
    name: "Courtier",
    edition: "bmr",
    team: "Townsfolk",
    reminders: [
      {
        name: "entertain",
        type: "drunk",
        duration: 3
      }
    ],
    setup: false,
    delusional: false,
    ability: "Once per game, at night, choose a character: they are drunk for 3 nights & 3 days.",
    imageSrc: "courtier.png",
    firstNight: {
      reminder: "The Courtier either shows a 'no' head signal, or points to a character on the sheet. If the Courtier used their ability: If that character is in play, that player is drunk.",
      order: 19,
      setReminders: ["entertain"]
    },
    otherNight: {
      reminder: "Reduce the remaining number of days the marked player is poisoned. If the Courtier has not yet used their ability: The Courtier either shows a 'no' head signal, or points to a character on the sheet. If the Courtier used their ability: If that character is in play, that player is drunk.",
      order: 8,
      setReminders: ["entertain"]
    }
  },
  {
    id: "devils_advocate",
    name: "Devil's Advocate",
    edition: "bmr",
    team: "Minion",
    reminders: [
      {
        name: "advocate",
        type: "protected"
      }
    ],
    setup: false,
    delusional: false,
    ability: "Each night, choose a living player (different to last night): if executed tomorrow, they don't die.",
    imageSrc: "devilsadvocate.png",
    firstNight: {
      reminder: "The Devil\u2019s Advocate points to a living player. That player survives execution tomorrow.",
      order: 22,
      setReminders: ["advocate"]
    },
    otherNight: {
      reminder: "The Devil\u2019s Advocate points to a living player, different from the previous night. That player survives execution tomorrow.",
      order: 13,
      setReminders: ["advocate"]
    }
  },
  {
    id: "dreamer",
    name: "Dreamer",
    edition: "snv",
    team: "Townsfolk",
    reminders: [{ name: "dream", type: "reveal-role" }],
    setup: false,
    delusional: false,
    ability: "Each night, choose a player (not yourself or Travelers): you learn 1 good and 1 evil character, 1 of which is correct.",
    imageSrc: "dreamer.png",
    firstNight: {
      reminder: "The Dreamer points to a player. Show 1 good and 1 evil character token; one of these is correct.",
      order: 42,
      setReminders: ["dream"],
      playerMessage: {
        type: "reveal-character",
        count: 2
      }
    },
    otherNight: {
      reminder: "The Dreamer points to a player. Show 1 good and 1 evil character token; one of these is correct.",
      order: 56,
      setReminders: ["dream"],
      playerMessage: {
        type: "reveal-character",
        count: 2
      }
    }
  },
  {
    id: "drunk",
    name: "Drunk",
    edition: "tb",
    team: "Outsider",
    reminders: [{ name: "the drunk", type: "drunk" }],
    setup: true,
    delusional: true,
    ability: "You do not know you are the Drunk. You think you are a Townsfolk character, but you are not.",
    imageSrc: "drunk.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "empath",
    name: "Empath",
    edition: "tb",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each night, you learn how many of your 2 alive neighbours are evil.",
    imageSrc: "empath.png",
    firstNight: {
      reminder: "Show the finger signal (0, 1, 2) for the number of evil alive neighbours of the Empath.",
      order: 37
    },
    otherNight: {
      reminder: "Show the finger signal (0, 1, 2) for the number of evil neighbours.",
      order: 53
    }
  },
  {
    id: "evil_twin",
    name: "Evil Twin",
    edition: "snv",
    team: "Minion",
    reminders: [
      {
        name: "twinsies",
        type: "reveal-role"
      }
    ],
    setup: false,
    delusional: false,
    ability: "You & an opposing player know each other. If the good player is executed, evil wins. Good can't win if you both live.",
    imageSrc: "eviltwin.png",
    firstNight: {
      reminder: "Wake the Evil Twin and their twin. Confirm that they have acknowledged each other. Point to the Evil Twin. Show their Evil Twin token to the twin player. Point to the twin. Show their character token to the Evil Twin player.",
      order: 23,
      setReminders: ["twinsies"]
    },
    otherNight: null
  },
  {
    id: "exorcist",
    name: "Exorcist",
    edition: "bmr",
    team: "Townsfolk",
    reminders: [{ name: "exorcist", duration: 1, type: "info" }],
    setup: false,
    delusional: false,
    ability: "Each night*, choose a player (different to last night): the Demon, if chosen, learns who you are then doesn't wake tonight.",
    imageSrc: "exorcist.png",
    firstNight: null,
    otherNight: {
      reminder: "The Exorcist points to a player, different from the previous night. If that player is the Demon: Wake the Demon. Show the Exorcist token. Point to the Exorcist. The Demon does not act tonight.",
      order: 21,
      setReminders: ["exorcist"]
    }
  },
  {
    id: "fang_gu",
    name: "Fang Gu",
    edition: "snv",
    team: "Demon",
    reminders: [{ name: "jumped", type: "info" }],
    setup: true,
    delusional: false,
    ability: "Each night*, choose a player: they die. The 1st Outsider this kills becomes an evil Fang Gu & you die instead. [+1 Outsider]",
    imageSrc: "fanggu.png",
    firstNight: null,
    otherNight: {
      reminder: "The Fang Gu points to a player. That player dies. Or, if that player was an Outsider and there are no other Fang Gu in play: The Fang Gu dies instead of the chosen player. The chosen player is now an evil Fang Gu. Wake the new Fang Gu. Show the 'You are' card, then the Fang Gu token. Show the 'You are' card, then the thumb-down 'evil' hand sign.",
      order: 29,
      playerMessage: {
        type: "role-change",
        alignmentChange: true
      },
      setReminders: ["jumped"],
      kills: true
    }
  },
  {
    id: "flowergirl",
    name: "Flowergirl",
    edition: "snv",
    team: "Townsfolk",
    reminders: [
      { name: "demon voted", type: "info", duration: 1, dayTrigger: true }
    ],
    setup: false,
    delusional: false,
    ability: "Each night*, you learn if a Demon voted today.",
    imageSrc: "flowergirl.png",
    firstNight: null,
    otherNight: {
      reminder: "Nod 'yes' or shake head 'no' for whether the Demon voted today. Place the 'Demon not voted' marker (remove 'Demon voted', if any).",
      order: 57
    }
  },
  {
    id: "fool",
    name: "Fool",
    edition: "bmr",
    team: "Townsfolk",
    reminders: [abilitySpent2("fool")],
    setup: false,
    delusional: false,
    ability: "The first time you die, you don't.",
    imageSrc: "fool.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "fortune_teller",
    name: "Fortune Teller",
    edition: "tb",
    team: "Townsfolk",
    reminders: [{ name: "red herring", type: "info" }],
    setup: false,
    delusional: false,
    ability: "Each night, choose 2 players: you learn if either is a Demon. There is a good player that registers as a Demon to you.",
    imageSrc: "fortuneteller.png",
    firstNight: {
      reminder: "The Fortune Teller points to two players. Give the head signal (nod yes, shake no) for whether one of those players is the Demon. ",
      order: 38,
      setReminders: ["red herring"]
    },
    otherNight: {
      reminder: "The Fortune Teller points to two players. Show the head signal (nod 'yes', shake 'no') for whether one of those players is the Demon.",
      order: 54
    }
  },
  {
    id: "gambler",
    name: "Gambler",
    edition: "bmr",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each night*, choose a player & guess their character: if you guess wrong, you die.",
    imageSrc: "gambler.png",
    firstNight: null,
    otherNight: {
      reminder: "The Gambler points to a player, and a character on their sheet. If incorrect, the Gambler dies.",
      order: 10,
      kills: true
    }
  },
  {
    id: "godfather",
    name: "Godfather",
    edition: "bmr",
    team: "Minion",
    reminders: [{ name: "see the targets", type: "reveal-role" }],
    setup: true,
    delusional: false,
    ability: "You start knowing which Outsiders are in play. If 1 died today, choose a player tonight: they die. [\u22121 or +1 Outsider]",
    imageSrc: "godfather.png",
    firstNight: {
      reminder: "Show each of the Outsider tokens in play.",
      order: 21,
      setReminders: ["see the targets"],
      playerMessage: {
        type: "character-selected-you",
        // count: 1,
        restriction: {
          team: ["Outsider"]
        }
      }
    },
    otherNight: {
      reminder: "If an Outsider died today: The Godfather points to a player. That player dies.",
      order: 37,
      kills: true
    }
  },
  {
    id: "goon",
    name: "Goon",
    edition: "bmr",
    team: "Outsider",
    reminders: [{ name: "gooned", type: "drunk", duration: 1 }],
    setup: false,
    delusional: false,
    ability: "Each night, the 1st player to choose you with their ability is drunk until dusk. You become their alignment.",
    imageSrc: "goon.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "gossip",
    name: "Gossip",
    edition: "bmr",
    team: "Townsfolk",
    reminders: [
      { name: "gossiped", type: "info", dayTrigger: true, duration: 1 }
    ],
    setup: false,
    delusional: false,
    ability: "Each day, you may make a public statement. Tonight, if it was true, a player dies.",
    imageSrc: "gossip.png",
    firstNight: null,
    otherNight: {
      reminder: "If the Gossip\u2019s public statement was true: Choose a player not protected from dying tonight. That player dies.",
      order: 38,
      kills: true
    }
  },
  {
    id: "grandmother",
    name: "Grandmother",
    edition: "bmr",
    team: "Townsfolk",
    reminders: [{ name: "grandchild", type: "triggerOnDeath" }],
    setup: false,
    delusional: false,
    ability: "You start knowing a good player & their character. If the Demon kills them, you die too.",
    imageSrc: "grandmother.png",
    firstNight: {
      reminder: "Show the marked character token. Point to the marked player.",
      order: 40,
      setReminders: ["grandchild"],
      playerMessage: {
        type: "reveal-role",
        count: 2,
        restriction: {
          team: ["Townsfolk", "Outsider"]
        }
      }
    },
    otherNight: {
      reminder: "If the Grandmother\u2019s grandchild was killed by the Demon tonight: The Grandmother dies.",
      order: 51
    }
  },
  {
    id: "imp",
    name: "Imp",
    edition: "tb",
    team: "Demon",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each night*, choose a player: they die. If you kill yourself this way, a Minion becomes the Imp.",
    imageSrc: "imp.png",
    firstNight: null,
    otherNight: {
      reminder: "The Imp points to a player. That player dies. If the Imp chose themselves: Replace the character of 1 alive minion with a spare Imp token. Show the 'You are' card, then the Imp token.",
      order: 24,
      kills: true
    }
  },
  {
    id: "innkeeper",
    name: "Innkeeper",
    edition: "bmr",
    team: "Townsfolk",
    reminders: [
      { name: "resting", type: "protected", duration: 1 },
      { name: "resting too much", type: "drunk", duration: 1 }
    ],
    setup: false,
    delusional: false,
    ability: "Each night*, choose 2 players: they can't die tonight, but 1 is drunk until dusk.",
    imageSrc: "innkeeper.png",
    firstNight: null,
    otherNight: {
      reminder: "The previously protected and drunk players lose those markers. The Innkeeper points to two players. Those players are protected. One is drunk.",
      order: 9,
      setReminders: ["resting", "resting", "resting too much"]
    }
  },
  {
    id: "investigator",
    name: "Investigator",
    edition: "tb",
    team: "Townsfolk",
    reminders: [{ name: "lead", type: "reveal-role" }],
    setup: false,
    delusional: false,
    ability: "You start knowing that 1 of 2 players is a particular Minion.",
    imageSrc: "investigator.png",
    firstNight: {
      reminder: "Show the character token of a Minion in play. Point to two players, one of which is that character.",
      order: 35,
      setReminders: ["lead"],
      playerMessage: {
        type: "reveal-role",
        count: 2,
        restriction: {
          team: ["Minion"]
        }
      }
    },
    otherNight: null
  },
  {
    id: "juggler",
    name: "Juggler",
    edition: "snv",
    team: "Townsfolk",
    reminders: [{ name: "juggled correct", type: "counter" }],
    setup: false,
    delusional: false,
    ability: "On your 1st day, publicly guess up to 5 players' characters. That night, you learn how many you got correct.",
    imageSrc: "juggler.png",
    firstNight: null,
    otherNight: {
      setReminders: ["juggled correct"],
      reminder: "If today was the Juggler\u2019s first day: Show the hand signal for the number (0, 1, 2, etc.) of 'Correct' markers. Remove markers.",
      order: 61
    }
  },
  {
    id: "klutz",
    name: "Klutz",
    edition: "snv",
    team: "Outsider",
    reminders: [
      { name: "klutz", type: "triggerOnDeath" },
      {
        name: "slipped on a banana",
        type: "info",
        causedByDeath: true,
        dayReminder: true
      }
    ],
    setup: false,
    delusional: false,
    ability: "When you learn that you died, publicly choose 1 alive player: if they are evil, your team loses.",
    imageSrc: "klutz.png",
    setupReminders: ["klutz"],
    firstNight: null,
    otherNight: null
  },
  {
    id: "librarian",
    name: "Librarian",
    edition: "tb",
    team: "Townsfolk",
    reminders: [{ name: "potential outsider", type: "reveal-role" }],
    setup: false,
    delusional: false,
    ability: "You start knowing that 1 of 2 players is a particular Outsider. (Or that zero are in play.)",
    imageSrc: "librarian.png",
    firstNight: {
      reminder: "Show the character token of an Outsider in play. Point to two players, one of which is that character.",
      order: 34,
      setReminders: ["potential outsider"],
      playerMessage: {
        type: "reveal-role",
        count: 2,
        restriction: {
          team: ["Outsider"]
        }
      }
    },
    otherNight: null
  },
  {
    id: "mastermind",
    name: "Mastermind",
    edition: "bmr",
    team: "Minion",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "If the Demon dies by execution (ending the game), play for 1 more day. If a player is then executed, their team loses.",
    imageSrc: "mastermind.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "mathematician",
    name: "Mathematician",
    edition: "snv",
    team: "Townsfolk",
    reminders: [{ name: "abnormal", type: "counter" }],
    setup: false,
    delusional: false,
    ability: "Each night, you learn how many players\u2019 abilities worked abnormally (since dawn) due to another character's ability.",
    imageSrc: "mathematician.png",
    firstNight: {
      setReminders: ["abnormal"],
      reminder: "Show the hand signal for the number (0, 1, 2, etc.) of players whose ability malfunctioned due to other abilities.",
      order: 52
    },
    otherNight: {
      setReminders: ["abnormal"],
      reminder: "Show the hand signal for the number (0, 1, 2, etc.) of players whose ability malfunctioned due to other abilities.",
      order: 71
    }
  },
  {
    id: "mayor",
    name: "Mayor",
    edition: "tb",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "If only 3 players live & no execution occurs, your team wins. If you die at night, another player might die instead.",
    imageSrc: "mayor.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "minstrel",
    name: "Minstrel",
    edition: "bmr",
    team: "Townsfolk",
    reminders: [
      { name: "minstrel", type: "triggerOnDeath" },
      { name: "listening", type: "poison", duration: 1 }
    ],
    setup: false,
    delusional: false,
    ability: "When a Minion dies by execution, all other players (except Travellers) are drunk until dusk tomorrow.",
    imageSrc: "minstrel.png",
    setupReminders: ["minstrel"],
    firstNight: null,
    otherNight: null
  },
  {
    id: "monk",
    name: "Monk",
    edition: "tb",
    team: "Townsfolk",
    reminders: [{ name: "monk", type: "protected", duration: 1 }],
    setup: false,
    delusional: false,
    ability: "Each night*, choose a player (not yourself): they are safe from the Demon tonight.",
    imageSrc: "monk.png",
    firstNight: null,
    otherNight: {
      reminder: "The previously protected player is no longer protected. The Monk points to a player not themself. Mark that player 'Protected'.",
      order: 12,
      setReminders: ["monk"]
    }
  },
  {
    id: "moonchild",
    name: "Moonchild",
    edition: "bmr",
    team: "Outsider",
    reminders: [
      {
        name: "moonchild",
        type: "triggerOnDeath"
      },
      { name: "moonchild died", type: "info", dayReminder: true, duration: 1 }
    ],
    setup: false,
    delusional: false,
    ability: "When you learn that you died, publicly choose 1 alive player. Tonight, if it was a good player, they die.",
    imageSrc: "moonchild.png",
    setupReminders: ["moonchild"],
    firstNight: null,
    otherNight: {
      setReminders: ["moonchild died"],
      reminder: "If the Moonchild used their ability to target a player today: If that player is good, they die.",
      order: 50
    }
  },
  {
    id: "mutant",
    name: "Mutant",
    edition: "snv",
    team: "Outsider",
    reminders: [
      {
        name: "mutant",
        type: "mad",
        dayReminder: true
      }
    ],
    setup: false,
    delusional: false,
    ability: "If you are \u201Cmad\u201D about being an Outsider, you might be executed.",
    imageSrc: "mutant.png",
    setupReminders: ["mutant"],
    firstNight: null,
    otherNight: null
  },
  {
    id: "no_dashii",
    name: "No Dashii",
    edition: "snv",
    team: "Demon",
    reminders: [{ name: "gross", type: "poison" }],
    setup: false,
    delusional: false,
    ability: "Each night*, choose a player: they die. Your 2 Townsfolk neighbours are poisoned.",
    imageSrc: "nodashii.png",
    firstNight: null,
    otherNight: {
      setReminders: ["gross"],
      reminder: "The No Dashii points to a player. That player dies.",
      order: 30,
      kills: true
    }
  },
  {
    id: "oracle",
    name: "Oracle",
    edition: "snv",
    team: "Townsfolk",
    reminders: [
      {
        type: "info",
        name: "evildead",
        causedByDeath: true,
        persistOnDeath: true
      }
    ],
    setup: false,
    delusional: false,
    ability: "Each night*, you learn how many dead players are evil.",
    imageSrc: "oracle.png",
    firstNight: null,
    otherNight: {
      reminder: "Show the hand signal for the number (0, 1, 2, etc.) of dead evil players.",
      order: 59
    }
  },
  {
    id: "pacifist",
    name: "Pacifist",
    edition: "bmr",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Executed good players might not die.",
    imageSrc: "pacifist.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "philosopher",
    name: "Philosopher",
    edition: "snv",
    team: "Townsfolk",
    reminders: [{ name: "dumbfounded", type: "drunk" }],
    setup: false,
    delusional: false,
    ability: "Once per game, at night, choose a good character: gain that ability. If this character is in play, they are drunk.",
    imageSrc: "philosopher.png",
    firstNight: {
      reminder: "The Philosopher either shows a 'no' head signal, or points to a good character on their sheet. If they chose a character: Swap the out-of-play character token with the Philosopher token and add the 'Is the Philosopher' reminder. If the character is in play, place the drunk marker by that player.",
      order: 2
    },
    otherNight: {
      reminder: "If the Philosopher has not used their ability: the Philosopher either shows a 'no' head signal, or points to a good character on their sheet. If they chose a character: Swap the out-of-play character token with the Philosopher token and add the 'Is the Philosopher' reminder. If the character is in play, place the drunk marker by that player.",
      order: 2
    }
  },
  {
    id: "pit-hag",
    name: "Pit-Hag",
    edition: "snv",
    team: "Minion",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each night*, choose a player & a character they become (if not-in-play). If a Demon is made, deaths tonight are arbitrary.",
    imageSrc: "pithag.png",
    firstNight: null,
    otherNight: {
      reminder: "The Pit-Hag points to a player and a character on the sheet. If this character is not in play, wake that player and show them the 'You are' card and the relevant character token. If the character is in play, nothing happens.",
      order: 16,
      playerMessage: {
        type: "role-change",
        alignmentChange: false
      }
    }
  },
  {
    id: "po",
    name: "Po",
    edition: "bmr",
    team: "Demon",
    reminders: [{ name: "charged", type: "info" }],
    setup: false,
    delusional: false,
    ability: "Each night*, you may choose a player: they die. If your last choice was no-one, choose 3 players tonight.",
    imageSrc: "po.png",
    firstNight: null,
    otherNight: {
      setReminders: ["charged"],
      reminder: "If the Po chose no-one the previous night: The Po points to three players. Otherwise: The Po either shows the 'no' head signal , or points to a player. Chosen players die",
      order: 28,
      kills: true
    }
  },
  {
    id: "poisoner",
    name: "Poisoner",
    edition: "tb",
    team: "Minion",
    reminders: [{ name: "poisoned", type: "poison" }],
    setup: false,
    delusional: false,
    ability: "Each night, choose a player: they are poisoned tonight and tomorrow day.",
    imageSrc: "poisoner.png",
    firstNight: {
      setReminders: ["poisoned"],
      reminder: "The Poisoner points to a player. That player is poisoned.",
      order: 17
    },
    otherNight: {
      setReminders: ["poisoned"],
      reminder: "The previously poisoned player is no longer poisoned. The Poisoner points to a player. That player is poisoned.",
      order: 7
    }
  },
  {
    id: "professor",
    name: "Professor",
    edition: "bmr",
    team: "Townsfolk",
    reminders: [abilitySpent2("professor")],
    setup: false,
    delusional: false,
    ability: "Once per game, at night*, choose a dead player: if they are a Townsfolk, they are resurrected.",
    imageSrc: "professor.png",
    firstNight: null,
    otherNight: {
      reminder: "If the Professor has not used their ability: The Professor either shakes their head no, or points to a player. If that player is a Townsfolk, they are now alive.",
      order: 43,
      setReminders: [abilitySpent2("professor").name],
      playerMessage: {
        type: "revived"
      }
    }
  },
  {
    id: "pukka",
    name: "Pukka",
    edition: "bmr",
    team: "Demon",
    reminders: [{ name: "marked for death", type: "poison" }],
    setup: false,
    delusional: false,
    ability: "Each night, choose a player: they are poisoned. The previously poisoned player dies then becomes healthy.",
    imageSrc: "pukka.png",
    firstNight: {
      setReminders: ["marked for death"],
      reminder: "The Pukka points to a player. That player is poisoned.",
      order: 28
    },
    otherNight: {
      setReminders: ["marked for death"],
      reminder: "The Pukka points to a player. That player is poisoned. The previously poisoned player dies. ",
      order: 26,
      kills: true
    }
  },
  {
    id: "ravenkeeper",
    name: "Ravenkeeper",
    edition: "tb",
    team: "Townsfolk",
    reminders: [
      {
        name: "ravenkeeper",
        type: "triggerOnDeath",
        causedByDeath: true,
        duration: 1
      }
    ],
    setup: false,
    delusional: false,
    ability: "If you die at night, you are woken to choose a player: you learn their character.",
    imageSrc: "ravenkeeper.png",
    firstNight: null,
    otherNight: {
      reminder: "If the Ravenkeeper died tonight: The Ravenkeeper points to a player. Show that player\u2019s character token.",
      order: 52,
      playerMessage: {
        type: "reveal-character",
        count: 1
      }
    }
  },
  {
    id: "recluse",
    name: "Recluse",
    edition: "tb",
    team: "Outsider",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "You might register as evil & as a Minion or Demon, even if dead.",
    imageSrc: "recluse.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "sage",
    name: "Sage",
    edition: "snv",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "If the Demon kills you, you learn that it is 1 of 2 players.",
    imageSrc: "sage.png",
    firstNight: null,
    otherNight: {
      reminder: "If the Sage was killed by a Demon: Point to two players, one of which is that Demon.",
      order: 42,
      playerMessage: {
        type: "reveal-team",
        count: 2,
        restriction: {
          team: ["Demon"]
        }
      }
    }
  },
  {
    id: "sailor",
    name: "Sailor",
    edition: "bmr",
    team: "Townsfolk",
    reminders: [{ name: "hammered", type: "drunk" }],
    setup: false,
    delusional: false,
    ability: "Each night, choose an alive player: either you or they are drunk until dusk. You can't die.",
    imageSrc: "sailor.png",
    firstNight: {
      setReminders: ["hammered"],
      reminder: "The Sailor points to a living player. Either the Sailor, or the chosen player, is drunk.",
      order: 11
    },
    otherNight: {
      setReminders: ["hammered"],
      reminder: "The previously drunk player is no longer drunk. The Sailor points to a living player. Either the Sailor, or the chosen player, is drunk.",
      order: 4
    }
  },
  {
    id: "saint",
    name: "Saint",
    edition: "tb",
    team: "Outsider",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "If you die by execution, your team loses.",
    imageSrc: "saint.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "savant",
    name: "Savant",
    edition: "snv",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each day, you may visit the Storyteller to learn 2 things in private: 1 is true & 1 is false.",
    imageSrc: "savant.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "scarlet_woman",
    name: "Scarlet Woman",
    edition: "tb",
    team: "Minion",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "If there are 5 or more players alive & the Demon dies, you become the Demon. (Travellers don\u2019t count)",
    imageSrc: "scarletwoman.png",
    firstNight: null,
    otherNight: {
      reminder: "If the Scarlet Woman became the Demon today: Show the 'You are' card, then the demon token.",
      order: 19,
      playerMessage: {
        type: "role-change",
        alignmentChange: true,
        restriction: {
          team: ["Demon"]
        }
      }
    }
  },
  {
    id: "seamstress",
    name: "Seamstress",
    edition: "snv",
    team: "Townsfolk",
    reminders: [abilitySpent2("seamstress")],
    setup: false,
    delusional: false,
    ability: "Once per game, at night, choose 2 players (not yourself): you learn if they are the same alignment.",
    imageSrc: "seamstress.png",
    firstNight: {
      setReminders: [abilitySpent2("seamstress").name],
      reminder: "The Seamstress either shows a 'no' head signal, or points to two other players. If the Seamstress chose players , nod 'yes' or shake 'no' for whether they are of same alignment.",
      order: 43
    },
    otherNight: {
      setReminders: [abilitySpent2("seamstress").name],
      reminder: "If the Seamstress has not yet used their ability: the Seamstress either shows a 'no' head signal, or points to two other players. If the Seamstress chose players , nod 'yes' or shake 'no' for whether they are of same alignment.",
      order: 60
    }
  },
  {
    id: "shabaloth",
    name: "Shabaloth",
    edition: "bmr",
    team: "Demon",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each night*, choose 2 players: they die. A dead player you chose last night might be regurgitated.",
    imageSrc: "shabaloth.png",
    firstNight: null,
    otherNight: {
      reminder: "One player that the Shabaloth chose the previous night might be resurrected. The Shabaloth points to two players. Those players die.",
      order: 27,
      playerMessage: {
        type: "revived"
      },
      kills: true
    }
  },
  {
    id: "slayer",
    name: "Slayer",
    edition: "tb",
    team: "Townsfolk",
    reminders: [
      {
        ...abilitySpent2("slayer"),
        dayTrigger: true
      }
    ],
    setup: false,
    delusional: false,
    ability: "Once per game, during the day, publicly choose a player: if they are the Demon, they die.",
    imageSrc: "slayer.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "snake_charmer",
    name: "Snake Charmer",
    edition: "snv",
    team: "Townsfolk",
    reminders: [{ name: "charmed", type: "poison" }],
    setup: false,
    delusional: false,
    ability: "Each night, choose an alive player: a chosen Demon swaps characters & alignments with you & is then poisoned.",
    imageSrc: "snakecharmer.png",
    firstNight: {
      setReminders: ["charmed"],
      reminder: "The Snake Charmer points to a player. If that player is the Demon: swap the Demon and Snake Charmer character and alignments. Wake each player to inform them of their new role and alignment. The new Snake Charmer is poisoned.",
      order: 20,
      playerMessage: {
        type: "role-change",
        alignmentChange: true
      }
    },
    otherNight: {
      setReminders: ["charmed"],
      reminder: "The Snake Charmer points to a player. If that player is the Demon: swap the Demon and Snake Charmer character and alignments. Wake each player to inform them of their new role and alignment. The new Snake Charmer is poisoned.",
      order: 11,
      playerMessage: {
        type: "role-change",
        alignmentChange: true
      }
    }
  },
  {
    id: "soldier",
    name: "Soldier",
    edition: "tb",
    team: "Townsfolk",
    reminders: [{ name: "sturdy", type: "protected" }],
    setup: false,
    delusional: false,
    ability: "You are safe from the Demon.",
    imageSrc: "soldier.png",
    setupReminders: ["sturdy"],
    firstNight: null,
    otherNight: null
  },
  {
    id: "spy",
    name: "Spy",
    edition: "tb",
    team: "Minion",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each night, you see the Grimoire. You might register as good & as a Townsfolk or Outsider, even if dead.",
    imageSrc: "spy.png",
    firstNight: {
      reminder: "Show the Grimoire to the Spy for as long as they need.",
      order: 49
    },
    otherNight: {
      reminder: "Show the Grimoire to the Spy for as long as they need.",
      order: 68
    }
  },
  {
    id: "sweetheart",
    name: "Sweetheart",
    edition: "snv",
    team: "Outsider",
    reminders: [
      { name: "loveable", type: "triggerOnDeath" },
      { name: "in love", type: "poison" }
    ],
    setup: false,
    delusional: false,
    ability: "When you die, 1 player is drunk from now on.",
    imageSrc: "sweetheart.png",
    setupReminders: ["loveable"],
    firstNight: null,
    otherNight: {
      setReminders: ["in love"],
      reminder: "Choose a player that is drunk.",
      order: 41
    }
  },
  {
    id: "tea_lady",
    name: "Tea Lady",
    edition: "bmr",
    team: "Townsfolk",
    reminders: [{ name: "rested", type: "protected" }],
    setup: false,
    delusional: false,
    ability: "If both your alive neighbours are good, they can't die.",
    imageSrc: "tealady.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "tinker",
    name: "Tinker",
    edition: "bmr",
    team: "Outsider",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "You might die at any time.",
    imageSrc: "tinker.png",
    firstNight: null,
    otherNight: {
      reminder: "The Tinker might die.",
      order: 49
    }
  },
  {
    id: "town_crier",
    name: "Town Crier",
    edition: "snv",
    team: "Townsfolk",
    reminders: [
      {
        name: "minions nominated today",
        type: "info",
        dayTrigger: true,
        duration: 1
      }
    ],
    setup: false,
    delusional: false,
    ability: "Each night*, you learn if a Minion nominated today.",
    imageSrc: "towncrier.png",
    firstNight: null,
    otherNight: {
      reminder: "Nod 'yes' or shake head 'no' for whether a Minion nominated today. Place the 'Minion not nominated' marker (remove 'Minion nominated', if any).",
      order: 58
    }
  },
  {
    id: "undertaker",
    name: "Undertaker",
    edition: "tb",
    team: "Townsfolk",
    reminders: [
      {
        name: "undertaker",
        type: "info",
        duration: 1,
        persistOnDeath: true,
        causedByDeath: true
      }
    ],
    setup: false,
    delusional: false,
    ability: "Each night*, you learn which character died by execution today.",
    imageSrc: "undertaker.png",
    firstNight: null,
    otherNight: {
      reminder: "If a player was executed today: Show that player\u2019s character token.",
      order: 55,
      playerMessage: {
        type: "reveal-character",
        count: 1
      }
    }
  },
  {
    id: "vigormortis",
    name: "Vigormortis",
    edition: "snv",
    team: "Demon",
    reminders: [
      { name: "poisoned neighbor", type: "poison" },
      { name: "minion keeps ability", type: "hasAbility" }
    ],
    setup: true,
    delusional: false,
    ability: "Each night*, choose a player: they die. Minions you kill keep their ability & poison 1 Townsfolk neighbour. [\u22121 Outsider]",
    imageSrc: "vigormortis.png",
    firstNight: null,
    otherNight: {
      reminder: "The Vigormortis points to a player. That player dies. If a Minion, they keep their ability and one of their Townsfolk neighbours is poisoned.",
      order: 32,
      setReminders: ["minion keeps ability", "poisoned neighbor"],
      kills: true
    }
  },
  {
    id: "virgin",
    name: "Virgin",
    edition: "tb",
    team: "Townsfolk",
    reminders: [
      {
        name: "virgin",
        type: "hasAbility",
        dayReminder: true
      }
    ],
    setup: false,
    delusional: false,
    ability: "The 1st time you are nominated, if the nominator is a Townsfolk, they are executed immediately.",
    imageSrc: "virgin.png",
    setupReminders: ["virgin"],
    firstNight: null,
    otherNight: null
  },
  {
    id: "vortox",
    name: "Vortox",
    edition: "snv",
    team: "Demon",
    reminders: [{ name: "vortox", type: "info" }],
    setup: false,
    delusional: false,
    ability: "Each night*, choose a player: they die. Townsfolk abilities yield false info. Each day, if no-one is executed, evil wins.",
    imageSrc: "vortox.png",
    firstNight: null,
    otherNight: {
      reminder: "The Vortox points to a player. That player dies.",
      order: 31,
      kills: true
    }
  },
  {
    id: "washerwoman",
    name: "Washerwoman",
    edition: "tb",
    team: "Townsfolk",
    reminders: [{ name: "customer", type: "reveal-role" }],
    setup: false,
    delusional: false,
    ability: "You start knowing that 1 of 2 players is a particular Townsfolk.",
    imageSrc: "washerwoman.png",
    firstNight: {
      reminder: "Show the character token of a Townsfolk in play. Point to two players, one of which is that character.",
      order: 33,
      setReminders: ["customer"],
      playerMessage: {
        type: "reveal-role",
        count: 2,
        restriction: { team: ["Townsfolk"] }
      }
    },
    otherNight: null
  },
  {
    id: "witch",
    name: "Witch",
    edition: "snv",
    team: "Minion",
    reminders: [{ name: "cursed", type: "info", dayReminder: true }],
    setup: false,
    delusional: false,
    ability: "Each night, choose a player: if they nominate tomorrow, they die. If just 3 players live, you lose this ability.",
    imageSrc: "witch.png",
    firstNight: {
      setReminders: ["cursed"],
      reminder: "The Witch points to a player. If that player nominates tomorrow they die immediately.",
      order: 24
    },
    otherNight: {
      setReminders: ["cursed"],
      reminder: "If there are 4 or more players alive: The Witch points to a player. If that player nominates tomorrow they die immediately.",
      order: 14
    }
  },
  {
    id: "zombuul",
    name: "Zombuul",
    edition: "bmr",
    team: "Demon",
    reminders: [{ name: "zombie", type: "info", persistOnDeath: true }],
    setup: false,
    delusional: false,
    ability: "Each night*, if no-one died today, choose a player: they die. The 1st time you die, you live but register as dead.",
    imageSrc: "zombuul.png",
    firstNight: null,
    otherNight: {
      reminder: "If no-one died during the day: The Zombuul points to a player. That player dies.",
      order: 25,
      kills: true
    }
  },
  {
    id: "alchemist",
    name: "Alchemist",
    edition: "",
    team: "Townsfolk",
    reminders: [{ type: "info", name: "alchemist" }],
    setup: false,
    delusional: false,
    ability: "You have a not-in-play Minion ability.",
    imageSrc: "alchemist.png",
    firstNight: {
      setReminders: ["alchemist"],
      reminder: "Show the Alchemist a not-in-play Minion token",
      order: 3,
      playerMessage: {
        type: "reveal-role",
        count: 0,
        restriction: {
          inPlay: true
        }
      }
    },
    otherNight: null
  },
  {
    id: "amnesiac",
    name: "Amnesiac",
    edition: "",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "You do not know what your ability is. Each day, privately guess what it is: you learn how accurate you are.",
    imageSrc: "amnesiac.png",
    firstNight: {
      reminder: "Decide the Amnesiac's entire ability. If the Amnesiac's ability causes them to wake tonight: Wake the Amnesiac and run their ability.",
      order: 32
    },
    otherNight: {
      reminder: "If the Amnesiac's ability causes them to wake tonight: Wake the Amnesiac and run their ability.",
      order: 47
    }
  },
  {
    id: "atheist",
    name: "Atheist",
    edition: "",
    team: "Townsfolk",
    reminders: [],
    setup: true,
    delusional: false,
    ability: "The Storyteller can break the game rules & if executed, good wins, even if you are dead. [No evil characters]",
    imageSrc: "atheist.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "balloonist",
    name: "Balloonist",
    edition: "",
    team: "Townsfolk",
    reminders: [
      { name: "seen townsfolk", type: "info" },
      { name: "seen outsider", type: "info" },
      { name: "seen minion", type: "info" },
      { name: "seen demon", type: "info" },
      { name: "seen traveler", type: "info" }
    ],
    setup: true,
    delusional: false,
    ability: "Each night, you learn 1 player of each character type, until there are no more types to learn. [+1 Outsider]",
    imageSrc: "balloonist.png",
    firstNight: {
      reminder: "Choose a character type. Point to a player whose character is of that type. Place the Balloonist's Seen reminder next to that character.",
      order: 45,
      playerMessage: {
        type: "reveal-team",
        count: 1
      }
    },
    otherNight: {
      reminder: "Choose a character type that does not yet have a Seen reminder next to a character of that type. Point to a player whose character is of that type, if there are any. Place the Balloonist's Seen reminder next to that character.",
      order: 62,
      playerMessage: {
        type: "reveal-team",
        count: 1
      }
    }
  },
  {
    id: "bounty_hunter",
    name: "Bounty Hunter",
    edition: "",
    team: "Townsfolk",
    reminders: [
      { name: "bounty", type: "triggerOnDeath" },
      { name: "turned", type: "info" }
    ],
    setup: true,
    delusional: false,
    ability: "You start knowing 1 evil player. If the player you know dies, you learn another evil player tonight. [1 Townsfolk is evil]",
    imageSrc: "bountyhunter.png",
    firstNight: {
      reminder: "Choose a townsfolk, they are evil. Wake this townsfolk and show them the 'You are' card and the thumbs down evil sign.",
      order: 46,
      playerMessage: {
        type: "alignment-change",
        restriction: {
          alignment: "Evil"
        }
      }
    },
    otherNight: {
      reminder: "If the known evil player has died, point to another evil player. ",
      order: 64
    }
  },
  {
    id: "cannibal",
    name: "Cannibal",
    edition: "",
    team: "Townsfolk",
    reminders: [
      {
        name: "evil died",
        type: "poison",
        causedByDeath: true,
        target: "self"
      },
      { name: "died today", type: "info", causedByDeath: true }
    ],
    setup: false,
    delusional: false,
    ability: "You have the ability of the recently killed executee. If they are evil, you are poisoned until a good player dies by execution.",
    imageSrc: "cannibal.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "choirboy",
    name: "Choirboy",
    edition: "",
    team: "Townsfolk",
    reminders: [],
    setup: true,
    delusional: false,
    ability: "If the Demon kills the King, you learn which player is the Demon. [+ the King]",
    imageSrc: "choirboy.png",
    firstNight: null,
    otherNight: {
      reminder: "If the King was killed by the Demon, wake the Choirboy and point to the Demon player.",
      order: 44,
      playerMessage: {
        type: "reveal-team",
        count: 1,
        restriction: {
          team: ["Demon"]
        }
      }
    }
  },
  {
    id: "cult_leader",
    name: "Cult Leader",
    edition: "",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each night, you become the alignment of an alive neighbour. If all good players choose to join your cult, your team wins.",
    imageSrc: "cultleader.png",
    firstNight: {
      reminder: "If the cult leader changed alignment, show them the thumbs up good signal of the thumbs down evil signal accordingly.",
      order: 48,
      playerMessage: {
        type: "alignment-change"
      }
    },
    otherNight: {
      reminder: "If the cult leader changed alignment, show them the thumbs up good signal of the thumbs down evil signal accordingly.",
      order: 66,
      playerMessage: {
        type: "alignment-change"
      }
    }
  },
  {
    id: "engineer",
    name: "Engineer",
    edition: "",
    team: "Townsfolk",
    reminders: [abilitySpent2("engineer")],
    setup: false,
    delusional: false,
    ability: "Once per game, at night, choose which Minions or which Demon is in play.",
    imageSrc: "engineer.png",
    firstNight: {
      setReminders: [abilitySpent2("engineer").name],
      reminder: "The Engineer shows a 'no' head signal, or points to a Demon or points to the relevant number of Minions. If the Engineer chose characters, replace the Demon or Minions with the choices, then wake the relevant players and show them the You are card and the relevant character tokens.",
      order: 13,
      playerMessage: {
        type: "role-change",
        alignmentChange: false
      }
    },
    otherNight: {
      setReminders: [abilitySpent2("engineer").name],
      reminder: "The Engineer shows a 'no' head signal, or points to a Demon or points to the relevant number of Minions. If the Engineer chose characters, replace the Demon or Minions with the choices, then wake the relevant players and show them the 'You are' card and the relevant character tokens.",
      order: 5,
      playerMessage: {
        type: "role-change",
        alignmentChange: false
      }
    }
  },
  {
    id: "farmer",
    name: "Farmer",
    edition: "",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "If you die at night, an alive good player becomes a Farmer.",
    imageSrc: "farmer.png",
    firstNight: null,
    otherNight: {
      reminder: "If a Farmer died tonight, choose another good player and make them the Farmer. Wake this player, show them the 'You are' card and the Farmer character token.",
      order: 48,
      playerMessage: {
        type: "role-change",
        alignmentChange: false
      }
    }
  },
  {
    id: "fisherman",
    name: "Fisherman",
    edition: "",
    team: "Townsfolk",
    reminders: [
      {
        ...abilitySpent2("fisherman"),
        dayTrigger: true
      }
    ],
    setup: false,
    delusional: false,
    ability: "Once per game, during the day, visit the Storyteller for some advice to help you win.",
    imageSrc: "fisherman.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "general",
    name: "General",
    edition: "",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each night, you learn which alignment the Storyteller believes is winning: good, evil, or neither.",
    imageSrc: "general.png",
    firstNight: {
      reminder: "Show the General thumbs up for good winning, thumbs down for evil winning or thumb to the side for neither.",
      order: 50
    },
    otherNight: {
      reminder: "Show the General thumbs up for good winning, thumbs down for evil winning or thumb to the side for neither.",
      order: 69
    }
  },
  {
    id: "huntsman",
    name: "Huntsman",
    edition: "",
    team: "Townsfolk",
    reminders: [abilitySpent2("huntsman")],
    setup: true,
    delusional: false,
    ability: "Once per game, at night, choose a living player: the Damsel, if chosen, becomes a not-in-play Townsfolk. [+the Damsel]",
    imageSrc: "huntsman.png",
    firstNight: {
      setReminders: [abilitySpent2("huntsman").name],
      reminder: "The Huntsman shakes their head 'no' or points to a player. If they point to the Damsel, wake that player, show the 'You are' card and a not-in-play character token.",
      order: 30,
      playerMessage: {
        type: "role-change",
        alignmentChange: false,
        restriction: {
          inPlay: false,
          team: ["Townsfolk"]
        }
      }
    },
    otherNight: {
      setReminders: [abilitySpent2("huntsman").name],
      reminder: "The Huntsman shakes their head 'no' or points to a player. If they point to the Damsel, wake that player, show the 'You are' card and a not-in-play character token.",
      order: 45,
      playerMessage: {
        alignmentChange: false,
        type: "role-change",
        restriction: {
          inPlay: false,
          team: ["Townsfolk"]
        }
      }
    }
  },
  {
    id: "king",
    name: "King",
    edition: "",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each night, if the dead outnumber the living, you learn 1 alive character. The Demon knows who you are.",
    imageSrc: "king.png",
    firstNight: {
      reminder: "Wake the Demon, show them the 'This character selected you' card, show the King token and point to the King player.",
      order: 10,
      playerMessage: {
        type: "character-selected-you",
        restriction: {
          role: ["king"]
        }
      }
    },
    otherNight: {
      reminder: "If there are more dead than living, show the King a character token of a living player.",
      order: 63,
      playerMessage: {
        type: "reveal-role",
        count: 0
      }
    }
  },
  {
    id: "lycanthrope",
    name: "Lycanthrope",
    edition: "",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each night*, choose a living player: if good, they die, but they are the only player that can die tonight.",
    imageSrc: "lycanthrope.png",
    firstNight: null,
    otherNight: {
      reminder: "The Lycanthrope points to a living player: if good, they die and no one else can die tonight.",
      order: 22
    }
  },
  {
    id: "magician",
    name: "Magician",
    edition: "",
    team: "Townsfolk",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "The Demon thinks you are a Minion. Minions think you are a Demon.",
    imageSrc: "magician.png",
    firstNight: {
      reminder: "When you wake the minions, show them both the magician and the demon.  When you wake the Demon, show them all minions and the magician",
      order: 5
    },
    otherNight: null
  },
  {
    id: "nightwatchman",
    name: "Nightwatchman",
    edition: "",
    team: "Townsfolk",
    reminders: [abilitySpent2("nightwatchman")],
    setup: false,
    delusional: false,
    ability: "Once per game, at night, choose a player: they learn who you are.",
    imageSrc: "nightwatchman.png",
    firstNight: {
      setReminders: [abilitySpent2("nightwatchman").name],
      reminder: "The Nightwatchman may point to a player. Wake that player, show the 'This character selected you' card and the Nightwatchman token, then point to the Nightwatchman player.",
      order: 47
    },
    otherNight: {
      setReminders: [abilitySpent2("nightwatchman").name],
      reminder: "The Nightwatchman may point to a player. Wake that player, show the 'This character selected you' card and the Nightwatchman token, then point to the Nightwatchman player.",
      order: 65,
      playerMessage: {
        type: "reveal-role",
        count: 1,
        restriction: {
          role: ["nightwatchman"]
        }
      }
    }
  },
  {
    id: "noble",
    name: "Noble",
    edition: "",
    team: "Townsfolk",
    reminders: [{ name: "seen", type: "reveal-role" }],
    setup: false,
    delusional: false,
    ability: "You start knowing 3 players, 1 and only 1 of which is evil.",
    imageSrc: "noble.png",
    firstNight: {
      setReminders: ["seen", "seen", "seen"],
      reminder: "Point to 3 players including one evil player, in no particular order.",
      order: 44,
      playerMessage: {
        type: "reveal-team",
        count: 3,
        restriction: {
          alignment: "Evil",
          guess: false
        }
      }
    },
    otherNight: null
  },
  {
    id: "pixie",
    name: "Pixie",
    edition: "",
    team: "Townsfolk",
    reminders: [
      { name: "mad", type: "mad", dayReminder: true },
      { name: "pixie ability", type: "hasAbility", causedByDeath: true }
    ],
    setup: false,
    delusional: false,
    ability: "You start knowing 1 in-play Townsfolk. If you were mad that you were this character, you gain their ability when they die.",
    imageSrc: "pixie.png",
    firstNight: {
      setReminders: ["mad", "pixie ability"],
      reminder: "Show the Pixie 1 in-play Townsfolk character token.",
      order: 29,
      playerMessage: {
        type: "reveal-role",
        count: 1,
        restriction: {
          team: ["Townsfolk"]
        }
      }
    },
    otherNight: null
  },
  {
    id: "poppy_grower",
    name: "Poppy Grower",
    edition: "",
    team: "Townsfolk",
    reminders: [{ name: "evil wakes", type: "info", causedByDeath: true }],
    setup: false,
    delusional: false,
    ability: "Minions & Demons do not know each other. If you die, they learn who each other are that night.",
    imageSrc: "poppygrower.png",
    firstNight: {
      reminder: "Do not inform the Demon/Minions who each other are",
      order: 4
    },
    otherNight: {
      setReminders: ["evil wakes"],
      reminder: "If the Poppy Grower has died, show the Minions/Demon who each other are.",
      order: 3,
      playerMessage: {
        type: "reveal-team",
        count: 2,
        restriction: {
          team: ["Demon", "Minion"]
        }
      }
    }
  },
  {
    id: "preacher",
    name: "Preacher",
    edition: "",
    team: "Townsfolk",
    reminders: [{ name: "no ability", type: "reveal-role" }],
    setup: false,
    delusional: false,
    ability: "Each night, choose a player: a Minion, if chosen, learns this. All chosen Minions have no ability.",
    imageSrc: "preacher.png",
    firstNight: {
      setReminders: ["no ability"],
      reminder: "The Preacher chooses a player. If a Minion is chosen, wake the Minion and show the 'This character selected you' card and then the Preacher token.",
      order: 14,
      playerMessage: {
        type: "character-selected-you"
      }
    },
    otherNight: {
      setReminders: ["no ability"],
      reminder: "The Preacher chooses a player. If a Minion is chosen, wake the Minion and show the 'This character selected you' card and then the Preacher token.",
      order: 6,
      playerMessage: {
        type: "character-selected-you"
      }
    }
  },
  {
    id: "acrobat",
    name: "Acrobat",
    edition: "",
    team: "Outsider",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each night*, if either good living neighbour is drunk or poisoned, you die.",
    imageSrc: "acrobat.png",
    firstNight: null,
    otherNight: {
      reminder: "If a good living neighbour is drunk or poisoned, the Acrobat player dies.",
      order: 39,
      kills: true
    }
  },
  {
    id: "damsel",
    name: "Damsel",
    edition: "",
    team: "Outsider",
    reminders: [
      {
        name: "guessed damsel",
        type: "info",
        dayTrigger: true,
        target: "other"
      }
    ],
    setup: false,
    delusional: false,
    ability: "All Minions know you are in play. If a Minion publicly guesses you (once), your team loses.",
    imageSrc: "damsel.png",
    firstNight: {
      setReminders: ["guessed damsel"],
      reminder: "Wake all the Minions, show them the 'This character selected you' card and the Damsel token.",
      order: 31,
      playerMessage: {
        type: "character-selected-you"
      }
    },
    otherNight: {
      setReminders: ["guessed damsel"],
      reminder: "If selected by the Huntsman, wake the Damsel, show 'You are' card and a not-in-play Townsfolk token.",
      order: 46,
      playerMessage: {
        type: "role-change",
        alignmentChange: false,
        restriction: {
          inPlay: false,
          team: ["Townsfolk"]
        }
      }
    }
  },
  {
    id: "golem",
    name: "Golem",
    edition: "",
    team: "Outsider",
    reminders: [
      {
        name: "can not nominate",
        type: "info",
        persistOnDeath: true,
        dayReminder: true,
        target: "self"
      }
    ],
    setup: false,
    delusional: false,
    ability: "You may only nominate once per game. When you do, if the nominee is not the Demon, they die.",
    imageSrc: "golem.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "heretic",
    name: "Heretic",
    edition: "",
    team: "Outsider",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Whoever wins, loses & whoever loses, wins, even if you are dead.",
    imageSrc: "heretic.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "politician",
    name: "Politician",
    edition: "",
    team: "Outsider",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "If you were the player most responsible for your team losing, you change alignment & win, even if dead.",
    imageSrc: "politician.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "puzzlemaster",
    name: "Puzzlemaster",
    edition: "",
    team: "Outsider",
    reminders: [
      { name: "drunk", type: "drunk", persistOnDeath: true },
      abilitySpent2("puzzlemaster")
    ],
    setup: false,
    delusional: false,
    ability: "1 player is drunk, even if you die. If you guess (once) who it is, learn the Demon player, but guess wrong & get false info.",
    imageSrc: "puzzlemaster.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "snitch",
    name: "Snitch",
    edition: "",
    team: "Outsider",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Minions start knowing 3 not-in-play characters.",
    imageSrc: "snitch.png",
    firstNight: {
      reminder: "After Minion info wake each Minion and show them three not-in-play character tokens. These may be the same or different to each other and the ones shown to the Demon.",
      order: 7,
      playerMessage: {
        type: "character-selected-you",
        restriction: {
          team: ["Townsfolk", "Outsider"]
        }
      }
    },
    otherNight: null
  },
  {
    id: "boomdandy",
    name: "Boomdandy",
    edition: "",
    team: "Minion",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "If you are executed, all but 3 players die. 1 minute later, the player with the most players pointing at them dies.",
    imageSrc: "boomdandy.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "fearmonger",
    name: "Fearmonger",
    edition: "",
    team: "Minion",
    reminders: [{ name: "fear", type: "info", causedByDeath: true }],
    setup: false,
    delusional: false,
    ability: "Each night, choose a player. If you nominate & execute them, their team loses. All players know if you choose a new player.",
    imageSrc: "fearmonger.png",
    firstNight: {
      setReminders: ["fear"],
      reminder: "The Fearmonger points to a player. Place the Fear token next to that player and announce that a new player has been selected with the Fearmonger ability.",
      order: 26
    },
    otherNight: {
      setReminders: ["fear"],
      reminder: "The Fearmonger points to a player. If different from the previous night, place the Fear token next to that player and announce that a new player has been selected with the Fearmonger ability.",
      order: 17
    }
  },
  {
    id: "goblin",
    name: "Goblin",
    edition: "",
    team: "Minion",
    reminders: [{ name: "claimed", type: "info", dayReminder: true }],
    setup: false,
    delusional: false,
    ability: "If you publicly claim to be the Goblin when nominated & are executed that day, your team wins.",
    imageSrc: "goblin.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "legion",
    name: "Legion",
    edition: "",
    team: "Demon",
    reminders: [],
    setup: true,
    delusional: false,
    ability: "Each night*, a player might die. Executions fail if only evil voted. You register as a Minion too. [Most players are Legion]",
    imageSrc: "legion.png",
    firstNight: null,
    otherNight: {
      reminder: "Choose a player, that player dies.",
      order: 23,
      kills: true
    }
  },
  {
    id: "leviathan",
    name: "Leviathan",
    edition: "",
    team: "Demon",
    reminders: [
      {
        name: "good player executed",
        type: "info"
      }
    ],
    setup: false,
    delusional: false,
    ability: "If more than 1 good player is executed, you win. All players know you are in play. After day 5, evil wins.",
    imageSrc: "leviathan.png",
    firstNight: {
      reminder: "Place the Leviathan 'Day 1' marker. Announce 'The Leviathan is in play; this is Day 1.'",
      order: 54
    },
    otherNight: {
      setReminders: ["good player executed"],
      reminder: "Change the Leviathan Day reminder for the next day.",
      order: 73
    }
  },
  {
    id: "lunatic",
    name: "Lunatic",
    edition: "bmr",
    team: "Outsider",
    reminders: [{ name: "the lunatic", type: "drunk" }],
    setup: true,
    delusional: true,
    ability: "You think you are a Demon, but you are not. The Demon knows who you are & who you choose at night.",
    imageSrc: "lunatic.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "marionette",
    name: "Marionette",
    edition: "",
    team: "Minion",
    reminders: [],
    setup: true,
    delusional: true,
    ability: "You think you are a good character but you are not. The Demon knows who you are. [You neighbour the Demon]",
    imageSrc: "marionette.png",
    firstNight: {
      reminder: "Select one of the good players next to the Demon and place the Is the Marionette reminder token. Wake the Demon and show them the Marionette.",
      order: 12,
      playerMessage: {
        type: "reveal-role",
        count: 1,
        restriction: {
          role: ["marionette"]
        }
      }
    },
    otherNight: null
  },
  {
    id: "mezepheles",
    name: "Mezepheles",
    edition: "",
    team: "Minion",
    reminders: [
      {
        name: "turns evil",
        type: "info",
        dayTrigger: true,
        dayReminder: true,
        target: "other"
      },
      abilitySpent2("secret word")
    ],
    setup: false,
    delusional: false,
    ability: "You start knowing a secret word. The 1st good player to say this word becomes evil that night.",
    imageSrc: "mezepheles.png",
    firstNight: {
      setReminders: ["turns evil", abilitySpent2("secret word").name],
      reminder: "Show the Mezepheles their secret word.",
      order: 27
    },
    otherNight: {
      setReminders: ["turns evil", abilitySpent2("secret word").name],
      reminder: "Wake the 1st good player that said the Mezepheles' secret word and show them the 'You are' card and the thumbs down evil signal.",
      order: 18,
      playerMessage: {
        type: "alignment-change",
        restriction: {
          alignment: "Evil"
        }
      }
    }
  },
  {
    id: "psychopath",
    name: "Psychopath",
    edition: "",
    team: "Minion",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each day, before nominations, you may publicly choose a player: they die. If executed, you only die if you lose roshambo.",
    imageSrc: "psychopath.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "lleech",
    name: "Lleech",
    edition: "",
    team: "Demon",
    reminders: [
      {
        name: "leeched",
        type: "poison"
      },
      {
        name: "lleech dies",
        type: "triggerOnDeath",
        causedByDeath: true
      },
      {
        name: "protected",
        type: "protected"
      }
    ],
    setup: false,
    delusional: false,
    ability: "Each night*, choose a player: they die. You start by choosing an alive player: they are poisoned - you die if & only if they die.",
    imageSrc: "lleech.png",
    firstNight: {
      setReminders: ["leeched"],
      reminder: "The Lleech points to a player. Place the Poisoned reminder token.",
      order: 16
    },
    otherNight: {
      reminder: "The Lleech points to a player. That player dies.",
      order: 34,
      kills: true
    }
  },
  {
    id: "riot",
    name: "Riot",
    edition: "",
    team: "Demon",
    reminders: [],
    setup: true,
    delusional: false,
    ability: "Nominees die, but may nominate again immediately (on day 3, they must). After day 3, evil wins. [All Minions are Riot]",
    imageSrc: "riot.png",
    firstNight: null,
    otherNight: null
  },
  {
    id: "widow",
    name: "Widow",
    edition: "",
    team: "Demon",
    reminders: [
      {
        name: "widow poisoned",
        type: "poison",
        duration: 1
      },
      {
        name: "knows widow in game",
        type: "reveal-role",
        persistOnDeath: true,
        target: "other"
      }
    ],
    setup: false,
    delusional: false,
    ability: "On your 1st night, look at the Grimoire and choose a player: they are poisoned. 1 good player knows a Widow is in play.",
    imageSrc: "widow.png",
    firstNight: {
      setReminders: ["knows widow in game", "widow poisoned"],
      reminder: "Show the Grimoire to the Widow for as long as they need. The Widow points to a player. That player is poisoned. Wake a good player. Show the 'These characters are in play' card, then the Widow character token.",
      order: 18,
      playerMessage: {
        type: "character-selected-you",
        restriction: {
          role: ["widow"]
        }
      }
    },
    otherNight: null
  },
  {
    id: "bureaucrat",
    name: "Bureaucrat",
    edition: "tb",
    team: "Traveler",
    reminders: [
      {
        name: "3 votes",
        type: "info",
        duration: 1,
        dayReminder: true,
        target: "other"
      }
    ],
    setup: false,
    delusional: false,
    ability: "Each night, choose a player (not yourself): their vote counts as 3 votes tomorrow.",
    firstNight: {
      setReminders: ["3 votes"],
      reminder: "The Bureaucrat points to a player. Put the Bureaucrat's '3 votes' reminder by the chosen player's character token.",
      order: 1
    },
    otherNight: {
      setReminders: ["3 votes"],
      reminder: "The Bureaucrat points to a player. Put the Bureaucrat's '3 votes' reminder by the chosen player's character token.",
      order: 1
    },
    imageSrc: "bureaucrat.png"
  },
  {
    id: "thief",
    name: "Thief",
    edition: "tb",
    team: "Traveler",
    reminders: [
      {
        name: "negative vote",
        type: "info",
        duration: 1,
        dayReminder: true,
        target: "other"
      }
    ],
    setup: false,
    delusional: false,
    ability: "Each night, choose a player (not yourself): their vote counts negatively tomorrow.",
    firstNight: {
      setReminders: ["negative vote"],
      reminder: "The Thief points to a player. Put the Thief's 'Negative vote' reminder by the chosen player's character token.",
      order: 1
    },
    otherNight: {
      setReminders: ["negative vote"],
      reminder: "The Thief points to a player. Put the Thief's 'Negative vote' reminder by the chosen player's character token.",
      order: 1
    },
    imageSrc: "thief.png"
  },
  {
    id: "gunslinger",
    name: "Gunslinger",
    edition: "tb",
    team: "Traveler",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each day, after the 1st vote has been tallied, you may choose a player that voted: they die.",
    firstNight: null,
    otherNight: null,
    imageSrc: "gunslinger.png"
  },
  {
    id: "scapegoat",
    name: "Scapegoat",
    edition: "tb",
    team: "Traveler",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "If a player of your alignment is executed, you might be executed instead.",
    firstNight: null,
    otherNight: null,
    imageSrc: "scapegoat.png"
  },
  {
    id: "beggar",
    name: "Beggar",
    edition: "tb",
    team: "Traveler",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "You must use a vote token to vote. Dead players may choose to give you theirs. If so, you learn their alignment. You are sober & healthy.",
    firstNight: null,
    otherNight: null,
    imageSrc: "beggar.png"
  },
  {
    id: "apprentice",
    name: "Apprentice",
    edition: "bmr",
    team: "Traveler",
    reminders: [
      {
        name: "is the apprentice",
        type: "info",
        persistOnDeath: true,
        target: "self"
      }
    ],
    setup: false,
    delusional: false,
    ability: "On your 1st night, you gain a Townsfolk ability (if good), or a Minion ability (if evil).",
    firstNight: {
      setReminders: ["is the apprentice"],
      reminder: "Show the Apprentice the 'You are' card, then a Townsfolk or Minion token. In the Grimoire, replace the Apprentice token with that character token, and put the Apprentice's 'Is the Apprentice' reminder by that character token.",
      order: 1
    },
    otherNight: null,
    imageSrc: "apprentice.png"
  },
  {
    id: "matron",
    name: "Matron",
    edition: "bmr",
    team: "Traveler",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each day, you may choose up to 3 sets of 2 players to swap seats. Players may not leave their seats to talk in private.",
    firstNight: null,
    otherNight: null,
    imageSrc: "matron.png"
  },
  {
    id: "judge",
    name: "Judge",
    edition: "bmr",
    team: "Traveler",
    reminders: [{ ...abilitySpent2("judge"), dayTrigger: true }],
    setup: false,
    delusional: false,
    ability: "Once per game, if another player nominated, you may choose to force the current execution to pass or fail.",
    firstNight: null,
    otherNight: null,
    imageSrc: "judge.png"
  },
  {
    id: "bishop",
    name: "Bishop",
    edition: "bmr",
    team: "Traveler",
    reminders: [
      {
        name: "nominate good",
        type: "info",
        dayTrigger: true
      },
      {
        name: "nominate evil",
        type: "info",
        dayTrigger: true
      }
    ],
    setup: false,
    delusional: false,
    ability: "Only the Storyteller can nominate. At least 1 opposite player must be nominated each day.",
    firstNight: null,
    otherNight: null,
    imageSrc: "bishop.png"
  },
  {
    id: "voudon",
    name: "Voudon",
    edition: "bmr",
    team: "Traveler",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Only you and the dead can vote. They don't need a vote token to do so. A 50% majority is not required.",
    firstNight: null,
    otherNight: null,
    imageSrc: "voudon.png"
  },
  {
    id: "barista",
    name: "Barista",
    edition: "snv",
    team: "Traveler",
    reminders: [
      {
        name: "sober & healthy",
        type: "info",
        duration: 1
      },
      {
        name: "ability twice",
        type: "info",
        duration: 1
      }
    ],
    setup: false,
    delusional: false,
    ability: "Each night, until dusk, 1) a player becomes sober, healthy and gets true info, or 2) their ability works twice. They learn which.",
    firstNight: {
      setReminders: ["sober & healthy", "ability twice"],
      reminder: "Choose a player, wake them and tell them which Barista power is affecting them. Treat them accordingly (sober/healthy/true info or activate their ability twice).",
      order: 1
    },
    otherNight: {
      setReminders: ["sober & healthy", "ability twice"],
      reminder: "Choose a player, wake them and tell them which Barista power is affecting them. Treat them accordingly (sober/healthy/true info or activate their ability twice).",
      order: 1
    },
    imageSrc: "barista.png"
  },
  {
    id: "harlot",
    name: "Harlot",
    edition: "snv",
    team: "Traveler",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each night*, choose a living player: if they agree, you learn their character, but you both might die.",
    firstNight: null,
    otherNight: {
      reminder: "The Harlot points at any player. Then, put the Harlot to sleep. Wake the chosen player, show them the 'This character selected you' token, then the Harlot token. That player either nods their head yes or shakes their head no. If they nodded their head yes, wake the Harlot and show them the chosen player's character token. Then, you may decide that both players die.",
      order: 1
    },
    imageSrc: "harlot.png"
  },
  {
    id: "butcher",
    name: "Butcher",
    edition: "snv",
    team: "Traveler",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Each day, after the 1st execution, you may nominate again.",
    firstNight: null,
    otherNight: null,
    imageSrc: "butcher.png"
  },
  {
    id: "bone_collector",
    name: "Bone Collector",
    edition: "snv",
    team: "Traveler",
    reminders: [
      abilitySpent2("bone collector"),
      {
        name: "has ability while dead",
        type: "hasAbility",
        duration: 1,
        target: "other"
      }
    ],
    setup: false,
    delusional: false,
    ability: "Once per game, at night, choose a dead player: they regain their ability until dusk.",
    firstNight: null,
    otherNight: {
      setReminders: [
        "has ability while dead",
        abilitySpent2("bone collector").name
      ],
      reminder: "The Bone Collector either shakes their head no or points at any dead player. If they pointed at any dead player, put the Bone Collector's 'Has Ability' reminder by the chosen player's character token. (They may need to be woken tonight to use it.)",
      order: 1
    },
    imageSrc: "bonecollector.png"
  },
  {
    id: "deviant",
    name: "Deviant",
    edition: "snv",
    team: "Traveler",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "If you were funny today, you cannot die by exile.",
    firstNight: null,
    otherNight: null,
    imageSrc: "deviant.png"
  },
  {
    id: "gangster",
    name: "Gangster",
    edition: "",
    team: "Traveler",
    reminders: [],
    setup: false,
    delusional: false,
    ability: "Once per day, you may choose to kill an alive neighbour, if your other alive neighbour agrees.",
    firstNight: null,
    otherNight: null,
    imageSrc: "gangster.png"
  },
  {
    id: "lil_monsta",
    name: "Lil' Monsta",
    edition: "",
    team: "Demon",
    reminders: [],
    setup: true,
    ability: `Each night, Minions choose who babysits Lil' Monsta's token & "is the Demon". A player dies each night*. [+1 Minion]`,
    firstNight: {
      order: 15,
      reminder: "Wake all Minions together, allow them to vote by pointing at who they want to babysit Lil' Monsta."
    },
    otherNight: {
      order: 15,
      reminder: "Wake all Minions together, allow them to vote by pointing at who they want to babysit Lil' Monsta. Choose a player, that player dies.",
      kills: true
    },
    imageSrc: "lilmonsta.png"
  },
  {
    id: "blank_townsfolk",
    name: "Unknown Townsfolk",
    edition: "",
    team: "Townsfolk",
    reminders: [],
    setup: true,
    ability: "The storyteller will tell you your role.",
    firstNight: {
      order: 0,
      reminder: "Give this person a role."
    },
    otherNight: {
      order: 0,
      reminder: "Give this person a role."
    },
    imageSrc: ""
  },
  {
    id: "blank_outsider",
    name: "Unknown Outsider",
    edition: "",
    team: "Outsider",
    reminders: [],
    setup: true,
    ability: "The storyteller will tell you your role.",
    firstNight: {
      order: 0,
      reminder: "Give this person a role."
    },
    otherNight: {
      order: 0,
      reminder: "Give this person a role."
    },
    imageSrc: ""
  },
  {
    id: "blank_minion",
    name: "Unknown Minion",
    edition: "",
    team: "Minion",
    reminders: [],
    setup: true,
    ability: "The storyteller will tell you your role.",
    firstNight: {
      order: 0,
      reminder: "Give this person a role."
    },
    otherNight: {
      order: 0,
      reminder: "Give this person a role."
    },
    imageSrc: ""
  },
  {
    id: "blank_demon",
    name: "Unknown Demon",
    edition: "",
    team: "Demon",
    reminders: [],
    setup: true,
    ability: "The storyteller will tell you your role.",
    firstNight: {
      order: 0,
      reminder: "Give this person a role."
    },
    otherNight: {
      order: 0,
      reminder: "Give this person a role."
    },
    imageSrc: ""
  }
];

// src/gameData/fabled.ts
var FABLED = [
  {
    id: "doomsayer",
    firstNightReminder: "",
    otherNightReminder: "",
    reminders: [],
    setup: false,
    name: "Doomsayer",
    team: "fabled",
    ability: "If 4 or more players live, each living player may publicly choose (once per game) that a player of their own alignment dies."
  },
  {
    id: "angel",
    firstNightReminder: "",
    otherNightReminder: "",
    reminders: ["Protect", "Something Bad"],
    setup: false,
    name: "Angel",
    team: "fabled",
    ability: "Something bad might happen to whoever is most responsible for the death of a new player."
  },
  {
    id: "buddhist",
    firstNightReminder: "",
    otherNightReminder: "",
    reminders: [],
    setup: false,
    name: "Buddhist",
    team: "fabled",
    ability: "For the first 2 minutes of each day, veteran players may not talk."
  },
  {
    id: "hellslibrarian",
    firstNightReminder: "",
    otherNightReminder: "",
    reminders: ["Something Bad"],
    setup: false,
    name: "Hell's Librarian",
    team: "fabled",
    ability: "Something bad might happen to whoever talks when the Storyteller has asked for silence."
  },
  {
    id: "revolutionary",
    firstNightReminder: "",
    otherNightReminder: "",
    reminders: ["Used"],
    setup: false,
    name: "Revolutionary",
    team: "fabled",
    ability: "2 neighboring players are known to be the same alignment. Once per game, one of them registers falsely."
  },
  {
    id: "fiddler",
    firstNightReminder: "",
    otherNightReminder: "",
    reminders: [],
    setup: false,
    name: "Fiddler",
    team: "fabled",
    ability: "Once per game, the Demon secretly chooses an opposing player: all players choose which of these 2 players win."
  },
  {
    id: "toymaker",
    firstNightReminder: "",
    otherNight: 1,
    otherNightReminder: "If it is a night when a Demon attack could end the game, and the Demon is marked \u201CFinal night: No Attack,\u201D then the Demon does not act tonight. (Do not wake them.)",
    reminders: ["Final Night: No Attack"],
    setup: false,
    name: "Toymaker",
    team: "fabled",
    ability: "The Demon may choose not to attack & must do this at least once per game. Evil players get normal starting info."
  },
  {
    id: "fibbin",
    firstNightReminder: "",
    otherNightReminder: "",
    reminders: ["Used"],
    setup: false,
    name: "Fibbin",
    team: "fabled",
    ability: "Once per game, 1 good player might get false information."
  },
  {
    id: "duchess",
    firstNightReminder: "",
    otherNight: 1,
    otherNightReminder: "Wake each player marked \u201CVisitor\u201D or \u201CFalse Info\u201D one at a time. Show them the Duchess token, then fingers (1, 2, 3) equaling the number of evil players marked \u201CVisitor\u201D or, if you are waking the player marked \u201CFalse Info,\u201D show them any number of fingers except the number of evil players marked \u201CVisitor.\u201D",
    reminders: ["Visitor", "False Info"],
    setup: false,
    name: "Duchess",
    team: "fabled",
    ability: "Each day, 3 players may choose to visit you. At night*, each visitor learns how many visitors are evil, but 1 gets false info."
  },
  {
    id: "sentinel",
    firstNightReminder: "",
    otherNightReminder: "",
    reminders: [],
    setup: true,
    name: "Sentinel",
    team: "fabled",
    ability: "There might be 1 extra or 1 fewer Outsider in play."
  },
  {
    id: "spiritofivory",
    firstNightReminder: "",
    otherNightReminder: "",
    reminders: ["No extra evil"],
    setup: false,
    name: "Spirit of Ivory",
    team: "fabled",
    ability: "There can't be more than 1 extra evil player."
  },
  {
    id: "djinn",
    firstNight: 0,
    firstNightReminder: "",
    otherNightReminder: "",
    reminders: [],
    setup: false,
    name: "Djinn",
    team: "fabled",
    ability: "Use the Djinn's special rule. All players know what it is."
  },
  {
    id: "stormcatcher",
    firstNight: 1,
    firstNightReminder: 'Mark a good player as "Safe". Wake each evil player and show them the marked player.',
    otherNightReminder: "",
    reminders: ["Safe"],
    setup: false,
    name: "Storm Catcher",
    team: "fabled",
    ability: "Name a good character. If in play, they can only die by execution, but evil players learn which player it is."
  }
];
var FABLED_IDS = FABLED.map((item) => item.id);

// src/gameData/scripts.ts
var SCRIPTS = [
  {
    name: "Trouble Brewing",
    imageSrc: "https://wiki.bloodontheclocktower.com/images/a/a1/Logo_trouble_brewing.png",
    characters: [
      { id: "washerwoman" },
      { id: "librarian" },
      { id: "investigator" },
      { id: "chef" },
      { id: "empath" },
      { id: "fortune_teller" },
      { id: "undertaker" },
      { id: "monk" },
      { id: "ravenkeeper" },
      { id: "virgin" },
      { id: "slayer" },
      { id: "soldier" },
      { id: "mayor" },
      { id: "drunk" },
      { id: "butler" },
      { id: "recluse" },
      { id: "saint" },
      { id: "poisoner" },
      { id: "spy" },
      { id: "scarlet_woman" },
      { id: "baron" },
      { id: "imp" }
    ]
  },
  {
    name: "Sects & Violets",
    imageSrc: "https://wiki.bloodontheclocktower.com/images/4/43/Logo_sects_and_violets.png",
    characters: [
      { id: "clockmaker" },
      { id: "dreamer" },
      { id: "snake_charmer" },
      { id: "mathematician" },
      { id: "flowergirl" },
      { id: "town_crier" },
      { id: "oracle" },
      { id: "savant" },
      { id: "seamstress" },
      { id: "philosopher" },
      { id: "artist" },
      { id: "juggler" },
      { id: "sage" },
      { id: "mutant" },
      { id: "sweetheart" },
      { id: "barber" },
      { id: "klutz" },
      { id: "evil_twin" },
      { id: "witch" },
      { id: "cerenovus" },
      { id: "pit-hag" },
      { id: "fang_gu" },
      { id: "vigormortis" },
      { id: "no_dashii" },
      { id: "vortox" }
    ]
  },
  {
    name: "Bad Moon Rising",
    imageSrc: "https://wiki.bloodontheclocktower.com/images/1/10/Logo_bad_moon_rising.png",
    characters: [
      { id: "grandmother" },
      { id: "sailor" },
      { id: "chambermaid" },
      { id: "exorcist" },
      { id: "innkeeper" },
      { id: "gambler" },
      { id: "gossip" },
      { id: "courtier" },
      { id: "professor" },
      { id: "minstrel" },
      { id: "tea_lady" },
      { id: "pacifist" },
      { id: "fool" },
      { id: "goon" },
      { id: "lunatic" },
      { id: "tinker" },
      { id: "moonchild" },
      { id: "godfather" },
      { id: "devils_advocate" },
      { id: "assassin" },
      { id: "mastermind" },
      { id: "zombuul" },
      { id: "pukka" },
      { id: "shabaloth" },
      { id: "po" }
    ]
  },
  {
    name: "Catfishing",
    imageSrc: "https://content.osgnetworks.tv/gameandfishing/content/photos/gaf-RiverMonstersFelsher-Lead-1200x800.jpg",
    characters: [
      { id: "investigator" },
      { id: "chef" },
      { id: "grandmother" },
      { id: "balloonist" },
      { id: "dreamer" },
      { id: "fortune_teller" },
      { id: "snake_charmer" },
      { id: "gambler" },
      { id: "savant" },
      { id: "philosopher" },
      { id: "ravenkeeper" },
      { id: "amnesiac" },
      { id: "cannibal" },
      { id: "drunk" },
      { id: "recluse" },
      { id: "sweetheart" },
      { id: "mutant" },
      { id: "lunatic" },
      { id: "godfather" },
      { id: "cerenovus" },
      { id: "pit-hag" },
      { id: "widow" },
      { id: "imp" },
      { id: "vigormortis" },
      { id: "fang_gu" }
    ]
  },
  {
    name: "\u{1F336}\uFE0F TB",
    imageSrc: "https://i5.walmartimages.com/asr/d84e2293-9320-4867-b0f0-8532b1328cb7_1.2e5f695ebd8d419128c9e7c050de0999.jpeg?odnHeight=640&odnWidth=640&odnBg=FFFFFF",
    characters: [
      { id: "washerwoman" },
      { id: "librarian" },
      { id: "investigator" },
      { id: "chef" },
      { id: "empath" },
      { id: "fortune_teller" },
      { id: "undertaker" },
      { id: "monk" },
      { id: "ravenkeeper" },
      { id: "virgin" },
      { id: "slayer" },
      { id: "soldier" },
      { id: "mayor" },
      { id: "atheist" },
      { id: "drunk" },
      { id: "recluse" },
      { id: "saint" },
      { id: "lunatic" },
      { id: "baron" },
      { id: "poisoner" },
      { id: "scarlet_woman" },
      { id: "spy" },
      { id: "marionette" },
      { id: "imp" },
      { id: "legion" }
    ]
  },
  {
    name: "Uncertain Death",
    imageSrc: "https://quotefancy.com/media/wallpaper/1600x900/558572-Buddha-Quote-Life-is-uncertain-death-is-certain.jpg",
    characters: [
      { id: "clockmaker" },
      { id: "grandmother" },
      { id: "librarian" },
      { id: "empath" },
      { id: "fortune_teller" },
      { id: "exorcist" },
      { id: "flowergirl" },
      { id: "oracle" },
      { id: "undertaker" },
      { id: "artist" },
      { id: "slayer" },
      { id: "seamstress" },
      { id: "monk" },
      { id: "lunatic" },
      { id: "mutant" },
      { id: "sweetheart" },
      { id: "recluse" },
      { id: "godfather" },
      { id: "assassin" },
      { id: "scarlet_woman" },
      { id: "marionette" },
      { id: "no_dashii" },
      { id: "pukka" }
    ]
  },
  {
    name: "No Roles Barred",
    imageSrc: "",
    characters: [
      { id: "alchemist" },
      { id: "noble" },
      { id: "pixie" },
      { id: "bounty_hunter" },
      { id: "balloonist" },
      { id: "empath" },
      { id: "snake_charmer" },
      { id: "gossip" },
      { id: "amnesiac" },
      { id: "artist" },
      { id: "philosopher" },
      { id: "huntsman" },
      { id: "poppy_grower" },
      { id: "goon" },
      { id: "mutant" },
      { id: "damsel" },
      { id: "drunk" },
      { id: "godfather" },
      { id: "poisoner" },
      { id: "fearmonger" },
      { id: "goblin" },
      { id: "boomdandy" },
      { id: "lil_monsta" },
      { id: "legion" },
      { id: "vortox" }
    ]
  },
  {
    name: "Fall of Rome",
    imageSrc: "https://www.bloodstar.xyz/p/AlexS/Fall_of_Rome/_meta.png",
    characters: [
      { id: "sculptor_fall_of_rome" },
      { id: "vestalvirgin_fall_of_rome" },
      { id: "physician_fall_of_rome" },
      { id: "legionary_fall_of_rome" },
      { id: "trumpeter_fall_of_rome" },
      { id: "mortician_fall_of_rome" },
      { id: "standardbearer_fall_of_rome" },
      { id: "centurion1_fall_of_rome" },
      { id: "merchant_fall_of_rome" },
      { id: "gladiator_fall_of_rome" },
      { id: "actor_fall_of_rome" },
      { id: "blacksmith_fall_of_rome" },
      { id: "scholar_fall_of_rome" },
      { id: "thetwins_fall_of_rome" },
      { id: "winemaker_fall_of_rome" },
      { id: "spartacus_fall_of_rome" },
      { id: "badomen_fall_of_rome" },
      { id: "temptress_fall_of_rome" },
      { id: "haruspex_fall_of_rome" },
      { id: "glykon1_fall_of_rome" },
      { id: "augur_fall_of_rome" },
      { id: "cleopatra_fall_of_rome" },
      { id: "crassus_fall_of_rome" },
      { id: "hannibal_fall_of_rome" },
      { id: "caesar_fall_of_rome" },
      { id: "mercenary_fall_of_rome" },
      { id: "architect_fall_of_rome" },
      { id: "sibyl_fall_of_rome" },
      { id: "highpriest1_fall_of_rome" },
      { id: "highpriest2_fall_of_rome" },
      { id: "emperor_fall_of_rome" }
    ]
  }
];

// src/gameData/gameData.ts
var characters = Object.fromEntries(
  CHARACTERS.map((character) => [character.id, character])
);
var defaultAlignments = {
  Demon: "Evil",
  Minion: "Evil",
  Outsider: "Good",
  Townsfolk: "Good",
  Traveler: "Good"
};
var DistributionsByPlayerCount = {
  5: { Townsfolk: 3, Outsider: 0, Minion: 1, Demon: 1, Traveler: 0 },
  6: { Townsfolk: 3, Outsider: 1, Minion: 1, Demon: 1, Traveler: 0 },
  7: { Townsfolk: 5, Outsider: 0, Minion: 1, Demon: 1, Traveler: 0 },
  8: { Townsfolk: 5, Outsider: 1, Minion: 1, Demon: 1, Traveler: 0 },
  9: { Townsfolk: 5, Outsider: 2, Minion: 1, Demon: 1, Traveler: 0 },
  10: { Townsfolk: 7, Outsider: 0, Minion: 2, Demon: 1, Traveler: 0 },
  11: { Townsfolk: 7, Outsider: 1, Minion: 2, Demon: 1, Traveler: 0 },
  12: { Townsfolk: 7, Outsider: 2, Minion: 2, Demon: 1, Traveler: 0 },
  13: { Townsfolk: 9, Outsider: 0, Minion: 3, Demon: 1, Traveler: 0 },
  14: { Townsfolk: 9, Outsider: 1, Minion: 3, Demon: 1, Traveler: 0 },
  15: { Townsfolk: 9, Outsider: 2, Minion: 3, Demon: 1, Traveler: 0 },
  16: { Townsfolk: 11, Outsider: 0, Minion: 4, Demon: 1, Traveler: 0 },
  17: { Townsfolk: 11, Outsider: 1, Minion: 4, Demon: 1, Traveler: 0 },
  18: { Townsfolk: 11, Outsider: 2, Minion: 4, Demon: 1, Traveler: 0 },
  19: { Townsfolk: 13, Outsider: 0, Minion: 5, Demon: 1, Traveler: 0 },
  20: { Townsfolk: 13, Outsider: 1, Minion: 5, Demon: 1, Traveler: 0 },
  21: { Townsfolk: 13, Outsider: 2, Minion: 5, Demon: 1, Traveler: 0 }
};
function oppositeAlignment(alignment) {
  return alignment === "Good" ? "Evil" : "Good";
}
function getDefaultAlignment(role) {
  return defaultAlignments[getCharacter(role).team];
}
function getCharacter(role) {
  return characters[role ?? "unassigned"];
}
var allReminders = Object.fromEntries(
  allCharactersList().flatMap(
    ({ reminders, id }) => reminders.map((r) => ({ ...r, role: id }))
  ).map((reminder) => [reminder.name, reminder])
);
function getAllReminders() {
  return allReminders;
}
function getReminder(reminderName) {
  return allReminders[reminderName];
}
var scripts = Object.fromEntries(
  SCRIPTS.map(({ name, characters: characters2 }) => [name, characters2])
);
var scriptDefinitions = Object.fromEntries(
  SCRIPTS.map(
    (scriptDefinition) => [scriptDefinition.name, scriptDefinition]
  )
);
function getScript(scriptID) {
  return scripts[scriptID];
}
function getScriptImg(scriptID) {
  var _a;
  return ((_a = scriptDefinitions[scriptID]) == null ? void 0 : _a.imageSrc) || void 0;
}
function getScriptNames() {
  return Object.keys(scripts);
}
var travelers = CHARACTERS.filter((role) => role.team === "Traveler").map(({ id }) => id);
function allTravelers() {
  return travelers;
}
function validCharactersList() {
  return CHARACTERS.filter((c) => !c.delusional);
}
function allCharactersList() {
  return CHARACTERS;
}
function isTravelerRole(role) {
  return allTravelers().includes(role);
}
function isFabledRole(role) {
  return FABLED_IDS.includes(role);
}

// src/shapes/PlayerMessages.ts
import { z as z2 } from "zod";

// src/shapes/Role.ts
import { z } from "zod";
var CharacterTypes = [
  "Townsfolk",
  "Outsider",
  "Minion",
  "Demon",
  "Traveler"
];
var CharacterTypeShape = z.enum(CharacterTypes);
var alignmentShape = z.enum(["Good", "Evil"]);
var roleShape = z.string().refine((_arg) => true);

// src/shapes/PlayerMessages.ts
var playerMessageEntryShape = z2.object({
  character: roleShape.optional(),
  player: z2.ostring(),
  team: z2.enum(CharacterTypes).optional(),
  alignment: alignmentShape.optional(),
  message: z2.ostring(),
  group: z2.string()
});
var playerMessageShape = z2.object({
  id: z2.string(),
  nightNumber: z2.number(),
  showState: z2.enum(["needs to be shown", "shown", "do not show"]),
  messages: z2.array(playerMessageEntryShape)
});

// src/shapes/PlayerReminder.ts
import { z as z3 } from "zod";
var playerReminderShape = z3.object({
  name: z3.string(),
  toPlayer: z3.string(),
  fromPlayer: z3.string()
});
var appliedPlayerReminderShape = z3.intersection(
  playerReminderShape,
  z3.object({ id: z3.string(), active: z3.boolean(), startNight: z3.number() })
);

// src/shapes/UnifiedGame.ts
import { z as z4 } from "zod";
var gameStatusShape = z4.enum([
  "PlayersJoining",
  "Setup",
  "Started",
  "Finished"
]);
var dayNightShape = z4.enum(["day", "night"]);
var timeRecordShape = z4.object({
  time: dayNightShape,
  count: z4.number(),
  startTime: z4.number().nullable()
});

// src/utils/arrayUtils.ts
function unique(array) {
  return [...new Set(array)];
}

// src/utils/characterUtils.ts
function asRole(role) {
  return role;
}
function getAbility(role, time) {
  if (!role) {
    return null;
  }
  const isFirstNight = time.time === "night" && time.count === 1;
  const abilityKey = isFirstNight ? "firstNight" : "otherNight";
  return getCharacter(role)[abilityKey];
}

// src/utils/createId.ts
import { generate } from "random-words";
function generateThreeWordId() {
  return generate(3).join("_");
}

// src/utils/exhaustiveCheck.ts
function exhaustiveCheck(typeToCheck) {
  throw new Error(
    `Missed exhaustive check for ${typeof typeToCheck === "object" ? JSON.stringify(typeToCheck) : String(typeToCheck)}`
  );
}

// src/utils/objectUtils.ts
function toEntries(object) {
  return Object.entries(object);
}
function toKeys(object) {
  return Object.keys(object);
}
function removeKey(obj, key) {
  const { [key]: _, ...next } = obj;
  return next;
}
function mapObject(input, cb) {
  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => cb(key, value)).filter((v) => !!v)
  );
}
function filterObject(input, filter) {
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
    Object.entries(input).filter(filter)
  );
}
function filterFalsy(arg) {
  return !!arg;
}
function groupBy(baseList, keyBy) {
  return baseList.reduce(
    (next, value) => {
      const keyOfNext = value[keyBy];
      if (!next[keyOfNext]) {
        next[keyOfNext] = [];
      }
      next[keyOfNext].push(value);
      return next;
    },
    {}
  );
}

// src/utils/shuffleList.ts
function shuffleList(list) {
  return list.map((item) => [item, Math.random()]).sort(([, order1], [, order2]) => order1 - order2).map(([item]) => item);
}
function pick(count, list) {
  return shuffleList(list).slice(0, count);
}
function pluck(list) {
  return pick(1, list)[0];
}

// src/utils/scriptUtils.ts
function getRandomCharactersForDistribution(script, playerCount) {
  const characters2 = script.map((role) => getCharacter(role.id)).filter((character) => !character.delusional);
  const characterByTeam = characters2.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.team]: [...acc[curr.team] ?? [], curr]
    }),
    {}
  );
  const counts = DistributionsByPlayerCount[playerCount];
  return toKeys(characterByTeam).flatMap(
    (team) => pick(counts[team], characterByTeam[team])
  );
}
export {
  CHARACTERS,
  CharacterTypeShape,
  CharacterTypes,
  DistributionsByPlayerCount,
  FABLED,
  FABLED_IDS,
  SCRIPTS,
  UNASSIGNED,
  alignmentShape,
  allCharactersList,
  allTravelers,
  appliedPlayerReminderShape,
  asRole,
  dayNightShape,
  defaultAlignments,
  exhaustiveCheck,
  filterFalsy,
  filterObject,
  gameStatusShape,
  generateThreeWordId,
  getAbility,
  getAllReminders,
  getCharacter,
  getDefaultAlignment,
  getRandomCharactersForDistribution,
  getReminder,
  getScript,
  getScriptImg,
  getScriptNames,
  groupBy,
  isFabledRole,
  isTravelerRole,
  mapObject,
  oppositeAlignment,
  pick,
  playerMessageEntryShape,
  playerMessageShape,
  playerReminderShape,
  pluck,
  removeKey,
  roleShape,
  shuffleList,
  timeRecordShape,
  toEntries,
  toKeys,
  unique,
  validCharactersList
};
